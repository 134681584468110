.no-result {
    text-align: center;

    &__title {
        font-family: $ff-tiempos;
        font-size: 36px;
        font-weight: $fw-light;
    }

    &__description {
        margin-top: 20px;
        font-size: 20px;
    }
}
