select {
    @include input;
    @include input-form;
    @include select;
}

select[multiple] {
    @include pretty-scroll;

    background-image: none;

    @include tablet {
        height: 200px;
    }

    option {
        padding: 10px;

        &:checked {
            background-color: $color-main-dark;
            color: $color-white;
        }
    }

    option + option {
        margin-top: 6px;
    }
}
