.predictive-search {
    background-color: $color-white;
    color: $color-main-dark;

    &__list {
        @include pretty-scroll;

        overflow-y: auto;
        max-height: 150px;

        @include tablet {
            max-height: 225px;
        }
    }

    &__link {
        display: block;
        padding: 1em 1.25em;
        background-color: $color-white;
        color: inherit;
        font-size: 16px;
        text-decoration: none;
        transition: background-color 0.2s, opacity 0.2s;

        @include tablet {
            font-size: 18px;
        }

        &:hover {
            background-color: darken($color-white, 20);
        }

        &:active {
            opacity: 0.6;
        }
    }
}
