.article-card-remove {
    @include reset-button;

    margin-top: 12px;
    padding-left: 0;
    color: inherit;
    font-family: $ff-tiempos;
    font-style: italic;
    font-weight: $fw-light;
    text-decoration: underline;
    cursor: pointer;
    transition: transform 0.3s ease-out;

    @include hover-focus {
        transform: translateX(2px);
    }
}
