.person-card {
    $this: &;

    &__media {
        @include objectFitImageParent;

        position: relative;

        &::before {
            content: '';
            display: block;
            padding-top: 100%;
        }

        #{$this}--desktop-row & {
            @include desktop {
                width: col(1);
                min-width: col(1);
                min-height: 100%;
            }
        }
    }

    &__content {
        margin-top: 24px;
        color: var(--color-main-dark);

        @include until-desktop {
            text-align: center;
        }

        #{$this}--desktop-row & {
            @include desktop {
                margin-top: 0;
                margin-left: 24px;
            }
        }
    }

    &__title {
        font-family: $ff-suisse;
        font-size: 20px;
        font-weight: $fw-bold;
        line-height: (32/20);
    }

    &__sup {
        margin-top: 6px;
        font-family: $ff-suisse;
        font-size: 16px;
        font-weight: $fw-light;
        line-height: (28/16);
    }

    &__top-text {
        margin-bottom: 7px;
        font-family: $ff-tiempos;
        font-size: 16px;
        font-style: italic;
        font-weight: $fw-light;
        line-height: (28/16);
    }

    &--centered {
        text-align: center;
    }

    &--desktop-row {
        @include desktop {
            display: flex;
            align-items: center;
            text-align: left;
        }
    }
}
