.texts-card {
    $rich-text:'.richtext';
    width: 100%;

    &__title, #{$rich-text} &__title {
        margin-bottom: 18px;
        font-family: $ff-tiempos;
        font-size: 24px;
        font-style: italic;
        font-weight: $fw-light;
        line-height: 29px;

        @include desktop {
            margin-bottom: 24px;
            font-family: $ff-tiempos;
            font-size: 42px;
            font-style: italic;
            font-weight: $fw-light;
            line-height: 58px;
        }
    }

    &.richtext > *:first-child {
        margin-top: 16px;

        @include desktop {
            margin-top: 32px;
        }
    }

    &__subtitle, #{$rich-text} &__subtitle {
        margin-bottom: 16px;
        font-family: $ff-suisse;
        font-size: 20px;
        font-weight: $fw-medium;
        line-height: 28px;

        @include desktop {
            font-family: $ff-suisse;
            font-size: 20px;
            font-weight: $fw-bold;
            line-height: 32px;
        }
    }

    &__content,  #{$rich-text} &__content {
        font-size: 16px;
        line-height: 25px;
        font-family: $ff-suisse;
        font-weight: $fw-light;

        @include desktop {
            column-gap: 32px;
            column-count: 2;


            & > ul {
                page-break-inside: avoid;
                break-inside: avoid;
            }
        }

    }


    & ol, #{$rich-text} ol {
        font-size: 16px;
        line-height: 25px;
        font-family: $ff-suisse;
        font-weight: $fw-light;

        list-style: none;
        counter-reset: custom-counter;

        li {
            position: relative;
            padding-left: 30px;
            counter-increment: custom-counter;

            &::before {
                content: counter(custom-counter) '. ';
                position: absolute;
                left: 0;
                color: var(--button-color);
            }
        }
    }

    & ul, #{$rich-text} ul {
        font-size: 16px;
        line-height: 25px;
        font-family: $ff-suisse;
        font-weight: $fw-light;

        list-style: none;

        li {
            position: relative;
            padding-left: 20px;

            &::before {
                content: '';
                position: absolute;
                top: 9px;
                left: 0;
                clip-path: polygon(90% 15%, 80% 90%, 15% 100%, 0% 15%);
                width: 9px;
                height: 9px;
                background: var(--theme-color-primary);
            }
        }
    }

    & li+ li,  #{$rich-text} li + li {
        margin-top: 16px;
    }

     & ul + p, #{$rich-text} ul + p {
        margin-top: 24px;
    }

    & p + ul, #{$rich-text} p + ul {
        margin-top: 16px;
    }

    & p, #{$rich-text} p {
        font-size: 16px;
        line-height: 25px;
        font-family: $ff-suisse;
        font-weight: $fw-light;
        margin: 0 0 24px;

    }

}
