.hero-overlay {
    $this: &;
    $container-max-width: #{map-get($breakpoints, wide)};
    $theme-mustard: '.theme-mustard';
    $theme-pink: '.theme-pink';
    $theme-dusty-pink: '.theme-dusty-pink';
    $theme-pure-gold: '.theme-pure-gold';

    position: relative;
    overflow: hidden;
    z-index: 0;
    background-color: $color-dark-blue;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        display: block;
        width: 100%;
        height: 100%;
        background: var(--theme-color-accent);
        mask-image: linear-gradient(90deg,
            rgba($color-dark-blue, 1) 0%,
            rgba($color-dark-blue, 0.6) 10%,
            rgba($color-dark-blue, 0.6) 90%,
            rgba($color-dark-blue, 1) 100%);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -2;
        display: block;
        transform: translateX(-50%);
        width: 100vw;
        height: 100%;
        background: var(--theme-color-accent);
    }

    &__img-bg {
        position: absolute;
        z-index: -2;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__overhead-title {
        color: var(--theme-color-secondary);
        font-family: $ff-suisse;
        font-size: 16px;
        font-weight: 600;
        line-height: 25px;
        word-break: break-word;
    }

    &__title {
        margin-top: 16px;
        margin-bottom: 24px;
        color: var(--theme-color-secondary);
        font-family: $ff-tiempos;
        font-size: 39px;
        font-style: italic;
        font-weight: $fw-light;
        line-height: 39px;
        text-align: center;
        word-break: break-word;

        @include desktop {
            margin-bottom: 32px;
            font-size: 61px;
            line-height: 61px;
        }
    }

    .container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding-top: 116px;
        padding-bottom: 116px;

        @include desktop {
            padding-top: 240px;
            padding-bottom: 240px;
        }
    }

    #{$theme-mustard} &, #{$theme-pure-gold} &, #{$theme-dusty-pink} &, #{$theme-pink} & {
        & .btn {
            background: var(--color-main-dark);
            color: var(--color-white);

            &::before {
                background-color: var(--color-main-dark);
            }

            @include hover-focus {
                color: var(--color-main-dark);

                &::before {
                    background-color: var(--color-white);
                }
            }

        }
    }
}
