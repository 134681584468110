.article-tags {
    $this: &;

    --article-tags-gap: 12px;

    position: relative;

    @include tablet {
        --article-tags-gap: 4px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(var(--article-tags-gap) * -1);

        #{$this}--mobile-center & {
            @include mobile-only {
                justify-content: center;
            }
        }
    }

    &__item {
        margin-top: var(--article-tags-gap);
        margin-right: var(--article-tags-gap);

        #{$this}--mobile-center & {
            @include mobile-only {
                margin-right: calc(var(--article-tags-gap) / 2);
                margin-left: calc(var(--article-tags-gap) / 2);
            }
        }
    }

    &__btn {
        display: inline-block;
        padding: 7px 10px;
        background-color: var(--color-main-dark);
        border: 0;
        border-radius: 0;
        color: var(--color-white);
        font-family: inherit;
        font-size: 10px;
        text-align: center;
        text-decoration: none;
        transition: background 0.2s;

        @include tablet {
            padding: 11px 15px;
            font-size: 12px;
        }
    }

    &__btn:hover {
        background-color: lighten(#191919, 10);
    }

    &__btn:active {
        background-color: darken(#191919, 10);
    }
}
