.form-field {
    position: relative;

    .real-select {
        @include visually-hidden;
    }

    &--required::before {
        content: '';
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1;
        clip-path: polygon(90% 15%, 80% 90%, 15% 100%, 0% 15%);
        width: 9px;
        height: 9px;
        background: var(--color-main-dark);
    }

    [data-tippy-root] {
        width: 100%;
    }
}
