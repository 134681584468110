.article-layout {
    --aside-width: 100vw;
    --content-width: 100vw;
    --aside-offset-top: 0;
    --aside-offset-bottom: 0;
    --aside-main-offset-bottom: 60px;
    --article-offset-top: 40px;

    @include desktop {
        --aside-width: calc(42vw - var(--scrollbar) / 2);
        --content-width: calc(58vw - var(--scrollbar) / 2);
        --aside-offset-top: calc(#{col(1)} + 40px);
        --aside-offset-bottom: #{col(1)};
        --article-offset-top: 160px;
        --aside-main-offset-bottom: 0;
    }

    @include desktop-wide {
        --aside-width: calc(800px - var(--scrollbar) / 2);
        --content-width: calc(1120px - var(--scrollbar) / 2);
        --aside-offset-top: calc(#{col(1)} + 85px);
    }

    &__container {
        display: flex;
        flex-direction: column;
        max-width: var(--container-width);
        margin-right: auto;
        margin-left: auto;

        @include desktop {
            flex-direction: row;
        }
    }

    &__aside {
        position: relative;
        display: contents;
        width: var(--aside-width);
        color: var(--theme-color-secondary);

        @include desktop {
            display: block;
        }
    }

    &__aside::before {
        @include desktop {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100vw;
            height: 100%;
            background: var(--theme-color-primary);
        }
    }

    &__aside-wrapper {
        display: contents;

        @include desktop {
            position: sticky;
            top: 0;
            z-index: 5;
            display: block;
        }
    }

    &__aside-inner {
        display: contents;

        @supports (display: contents) {
            @include container;
        }

        @include desktop {
            @include pretty-scroll;

            overflow-y: auto;
            display: block;
            height: calc(var(--viewport-height));
            padding-top: var(--aside-offset-top);
            padding-right: col(0.5);
            padding-bottom: var(--aside-offset-bottom);
            padding-left: col(0.5);
        }

        @include desktop-wide {
            padding-right: col(1);
            padding-left: col(1);
        }
    }

    &__main {
        display: contents;
        width: var(--content-width);

        @include desktop {
            display: block;
        }
    }

    &__article {
        @include container;

        padding-top: var(--article-offset-top);
        padding-bottom: 64px;

        @include desktop {
            --container-width: var(--content-width);
            --grid-columns: 7;

            padding-right: col(1);
            padding-left: col(1);
        }
    }

    &__hero {
        @include mobile-small-only {
            margin-top: calc(
                (var(--article-offset-top) + var(--aside-main-offset-bottom) - 10px) * -1
            );
            margin-right: calc(var(--container-gap) * -1);
            margin-left: calc(var(--container-gap) * -1);
        }
    }
}
