.select {
    @include select;
    @include focusBoxShadowDark;

    &.is-active {
        background-image: url($icon-arrow-up);
    }

    &.is-nav {
        --select-arrow-position-right: 20px;

        padding-right: 50px;
        padding-left: 10px;

        @include mobile-tiny-only {
            --select-arrow-position-right: 10px;
            --select-arrow-width: 13px;
            --select-arrow-height: 13px;

            padding-right: 20px;
            font-size: 16px;
        }

        @include mobile-small {
            padding-left: 15px;
        }

        @include desktop {
            --select-arrow-position-right: 30px;

            padding-right: 80px;
        }
    }
}
