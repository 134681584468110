.share-burger-list {
    @include tablet {
        display: flex;
    }

    &__item {
        & + & {
            margin-top: 10px;

            @include tablet {
                margin-top: 0;
                margin-left: 24px;
            }
        }
    }

    &__btn {
        display: inline-block;
        padding: 10px 0;
        background: none;
        border: 0;
        border-radius: 0;
        color: inherit;
        font-family: $ff-tiempos;
        font-size: inherit;
        text-decoration: none;
        cursor: pointer;
        transition: opacity 0.2s;

        @include hover-focus {
            text-decoration: underline;
        }

        &:active {
            opacity: 0.6;
        }
    }
}
