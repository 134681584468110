.filters-row {
    margin-right: auto;
    margin-left: auto;

    @include until-desktop {
        width: 100%;
    }

    @include desktop {
        display: flex;
    }

    &__item {
        position: relative;

        @include desktop {
            min-width: 250px;
        }

        @include desktop-medium {
            min-width: 290px;
        }


        & + & {
            @include until-desktop {
                margin-top: 12px;
            }

            @include desktop {
                margin-left: 33px;
            }
        }

        [data-tippy-root] {
            width: 100%;
        }
    }

    &--left {
        margin-left: 0;
    }
}
