.article-btns {
    display: flex;

    &__item {
        & + & {
            margin-left: -5px;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 43px;
        height: 43px;
    }

    &__btn {
        position: relative;
        padding: 0;
        background: none;
        border: 0;
        border-radius: 0;
        color: inherit;
        cursor: pointer;
        transition: opacity 0.2s, color 0.2s;

        @include hover-focus {
            color: var(--theme-article-btns-hover-color, inherit);

            &::before {
                background-color: $color-main-dark;
            }
        }
    }

    &__btn > * {
        position: relative;
    }

    &__btn:active {
        opacity: 0.6;
    }

    &__btn::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 43px;
        height: 43px;
        transition: background 0.2s;
    }

    &__icon {
        position: relative;
    }
}
