.modal {
    $this: &;

    position: fixed;
    z-index: $layer-modal;

    @include desktop-only {
        --container-width: 100vw;
    }

    &__overlay {
        position: absolute;
        z-index: -1;
        background-color: var(--color-tranparent-black);

        #{$this}--bookmarks & {
            background-color: var(--color-main-dark);
        }

        #{$this}--get-in-touch & {
            background: var(--color-main-dark);

            @include desktop {
                background: linear-gradient(to right, $color-main-dark, $color-main-dark 50%, $color-white 50%, $color-white);
            }
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        background-color: var(--color-white);
        color: var(--color-black);

        @include hover-focus {
            .icon {
                transform: scale(0.87);
            }
        }

        #{$this}--get-in-touch & {
            z-index: 1;
            border-width: 0 0 1px 1px;
            border-color: $color-meta-grey;
            border-style: solid;
        }
    }

    &__content {
        position: relative;
        overflow-y: auto;
        min-height: var(--viewport-height);
        max-height: var(--viewport-height);
        color: var(--color-white);
        -webkit-overflow-scrolling: touch;

        #{$this}--bookmarks & {
            padding-top: col(1);
            padding-bottom: col(1);
        }

        #{$this}--burger & {
            background: var(--color-main-dark);

            @include desktop {
                --container-gap: #{col(1)};

                background: linear-gradient(
                        to right,
                        transparent,
                        transparent 50%,
                        var(--color-main-dark) 50%,
                        var(--color-main-dark)
                );
            }
        }

        #{$this}--search & {
            width: 100%;
        }

        #{$this}--get-in-touch & {
            width: max-content;
            max-width: $container-max-width;
            margin-right: auto;
            margin-left: auto;
        }
    }

    &__content.is-scrollable + &__close {
        right: var(--scrollbar);
    }

    &,
    &__overlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
