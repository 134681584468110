.share-burger {
    @include tablet {
        display: flex;
        align-items: center;
        font-size: 14px;
    }

    &__title {
        opacity: 0.5;
        margin-bottom: 15px;

        @include tablet {
            margin-right: 24px;
            margin-bottom: 0;
        }
    }
}
