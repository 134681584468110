.generic-card {
    $this: &;

    --link-transform: 0;

    @include link-reset;

    display: block;


    &__media {
        position: relative;

        img {
            display: block;
            max-width: 100%;
        }
    }

    &__content {
        margin-top: 24px;
        color: var(--color-main-dark);
    }

    &__title {
        font-family: $ff-suisse;
        font-size: 20px;
        font-weight: $fw-medium;
        line-height: (32/20);
    }

    &__sup {
        margin-top: 6px;
        font-family: $ff-suisse;
        font-size: 16px;
        font-weight: $fw-light;
        line-height: (28/16);
    }

    &__top-text {
        margin-bottom: 7px;
        font-family: $ff-tiempos;
        font-size: 16px;
        font-style: italic;
        font-weight: $fw-light;
        line-height: (28/16);
    }

    &__link {
        @include link-reset;

        transform: translateX(var(--link-transform));
        margin-top: 16px;
        color: $color-main-dark;
        font-family: $ff-tiempos;
        font-style: italic;
        font-weight: $fw-light;
        line-height: (20/16);
        text-decoration: underline;
        transition: transform 0.2s ease-out;

        @include desktop {
            margin-top: 20px;
        }
    }

    @include hover-focus {
        --link-transform: 2px
    }
}
