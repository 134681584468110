.burger-menu {
    padding-top: 120px;
    padding-bottom: 120px;
    color: var(--color-white);

    @include desktop {
        display: flex;
        flex-direction: column;
        width: 70%;
        min-height: 100vh;
        margin-right: 0;
        margin-left: auto;
        padding: 80px 30px 30px;
        background-color: var(--color-main-dark);
    }

    @include desktop-medium {
        width: 50%;
        padding-left: 140px;
    }

    &__item {
        & + & {
            margin-top: 40px;

            @include desktop {
                margin-top: 40px;
            }
        }

        &--small {
            & + & {
                margin-top: 25px;

                @include desktop {
                    margin-top: 35px;
                }
            }
        }
    }

    &__main-link {
        @include link-reset;
        @include reset-button;

        padding: 0;
        font-size: 28px;
        font-weight: $fw-medium;
        line-height: 37px;
        cursor: pointer;
        transition: font-style 0.3s linear;

        @include desktop {
            font-size: 42px;
            line-height: 54px;
        }

        @include hover-focus {
            font-family: $ff-tiempos;
            font-style: italic;
            font-weight: $fw-light;
        }

        &--small {
            font-size: 20px;
            line-height: 26px;

            @include desktop {
                font-size: 28px;
                line-height: 37px;
            }
        }
    }

    &__sub-link {
        $gap: 40px;

        @include link-reset;

        position: relative;
        display: block;
        padding-left: $gap;
        font-family: $ff-tiempos;
        font-size: 18px;
        font-style: italic;
        font-weight: $fw-light;
        line-height: 22px;
        transition: transform 0.3s ease;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            transform: translateY(-50%);
            width: #{$gap - 10px};
            height: 1px;
            background-color: currentColor;
        }

        @include hover-focus {
            transform: translateX(5px);
        }
    }

    &__sub-item {
        & + & {
            margin-top: 10px;
        }
    }

    &__sublist {
        margin-top: 10px;
    }

    &__close {
        position: fixed;
        top: 0;
        right: 0;
    }

    &__share {
        padding-top: 40px;

        @include desktop {
            display: flex;
            justify-content: flex-end;
            margin-top: auto;
        }
    }
}
