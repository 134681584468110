.article {
    &__hero {
        @include objectFitImageParent;

        position: relative;

        &::before {
            content: '';
            display: block;
            padding-top: percentage(365/414);

            @include tablet {
                padding-top: percentage(500/800);
            }
        }
    }

    &__hero-img {
        clip-path: polygon(100% 0, 100% 100%, 0 91%, 0 12%);

        @include mobile-small {
            clip-path: polygon(95% 5%, 100% 100%, 0 92%, 10% 0);
        }
    }

    &__hero + * {
        margin-top: 20px;

        @include tablet {
            margin-top: get-offset(3);
        }
    }
}
