.section-forms {
    @include desktop-medium {
        display: flex;
        min-height: 100vh;
        max-height: 1440px;
    }

    &__item {
        @include desktop-medium {
            display: flex;
            align-items: center;
            width: 50%;
        }

        > * {
            @include desktop-medium {
                width: 100%;
            }
        }
    }
}
