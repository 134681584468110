.i-slider-media {
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 100%;

    & video {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        pointer-events: none;
    }

    & + .i-slider__content {
        background: $color-black;
    }
}
