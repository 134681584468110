.form-range {
    position: relative;
    display: flex;
    flex-direction: column;

    &__header {
        display: block;
        margin-bottom: 20px;
    }

    &__control {
        @include input-range;
    }

    &__tip {
        position: absolute;
        bottom: 15px;
        opacity: 0.7;
        font-size: 12px;

        &--min {
            left: 0;
        }

        &--max {
            right: 0;
        }
    }
}
