.form-checkbox {
    $this: &;

    position: relative;
    display: flex;
    cursor: pointer;

    &__control {
        flex-shrink: 0;
        margin-right: 14px;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        min-height: 36px;
    }
}
