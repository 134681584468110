.form-file-upload {
    $this: &;

    &__btn {
        display: inline-block;
        padding: 15px 30px;
        background-color: var(--color-white);
        border: 1px solid var(--theme-input-border-color, #E9EAEB);
        color: var(--color-main-dark);
        cursor: pointer;
        transition: opacity 0.2s;

        @include hover-focus {
            border-color: var(--theme-input-focus-border-color, var(--color-main-dark));
        }

        &:active {
            opacity: 0.7;
        }

        [data-whatintent='keyboard'] #{$this}__control:focus ~ &,
        [data-whatinput='keyboard'] #{$this}__control:focus ~ & {
            border-color: var(--theme-input-focus-border-color, var(--color-main-dark));
            box-shadow: var(--theme-input-focus-box-shadow, 0 0 0 2px #{$color-white});
        }
    }

    &__icon {
        margin-right: 5px;
    }

    &__control {
        @include visually-hidden;
    }
}
