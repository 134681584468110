.article-card {
    $this: &;
    $summary-gap: 16px;
    $title-lines-count: 2;
    $title-size: 20px;

    @include link-reset;

    &__media {
        @include objectFitImageParent;

        position: relative;
        overflow: hidden;
        z-index: 0;

        &::before {
            content: '';
            display: block;
            padding-top: percentage(197/406);
        }

        img {
            transition: transform 0.3s ease-out;
        }
    }

    &__icon-wrap {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background-color: var(--theme-color-primary);

        .icon {
            fill: var(--theme-color-secondary);
        }
    }

    &__content {
        $content-line-height: 24;
        $lines-count-to-show: 6;

        overflow: hidden;
        max-height: #{$summary-gap + $content-line-height * $lines-count-to-show};
        margin-top: 14px;
        font-size: 16px;
        line-height: #{$content-line-height / 16};

        @include mobile-tiny-only {
            $lines-count-to-show: 7;

            max-height: #{$summary-gap + $content-line-height * $lines-count-to-show};
        }

        @include tablet {
            $lines-count-to-show: 7;

            max-height: #{$summary-gap + $content-line-height * $lines-count-to-show};
        }

        @include desktop {
            $content-lines-count-to-show: 7;

            max-height: #{$summary-gap  + $title-size * 1.5 * $title-lines-count + $content-line-height * $content-lines-count-to-show};
        }

        @include desktop-medium {
            $content-lines-count-to-show: 5;

            max-height: #{$summary-gap  + $title-size * 1.5 * $title-lines-count + $content-line-height * $content-lines-count-to-show};
        }

        @include desktop-wide {
            $content-lines-count-to-show: 3;

            max-height: #{$summary-gap  + $title-size * 1.5 * $title-lines-count + $content-line-height * $content-lines-count-to-show};
        }

        .article-layout & {
            @include desktop-medium {
                $content-lines-count-to-show: 6;

                max-height: #{$summary-gap  + $title-size * 1.5 * $title-lines-count + $content-line-height * $content-lines-count-to-show};
            }

            @include desktop-wide {
                $content-lines-count-to-show: 5;

                max-height: #{$summary-gap  + $title-size * 1.5 * $title-lines-count + $content-line-height * $content-lines-count-to-show};
            }
        }
    }

    &__title {
        overflow: hidden;
        display: -webkit-box;
        font-size: 16px;
        font-weight: $fw-medium;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @include desktop {
            font-size: $title-size;
        }
    }

    &__summary {
        overflow: hidden;
        display: -webkit-box;
        margin-top: #{$summary-gap};
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;

        @include mobile-tiny-only {
            -webkit-line-clamp: 5;
            line-clamp: 5;
        }

        @include tablet {
            -webkit-line-clamp: 5;
            line-clamp: 5;
        }

        @include desktop {
            -webkit-line-clamp: 7;
            line-clamp: 7;
        }

        @include desktop-medium {
            -webkit-line-clamp: 4;
            line-clamp: 4;
        }

        @include desktop-wide {
            -webkit-line-clamp: 3;
            line-clamp: 3;
        }

        .article-layout & {
            @include desktop-medium {
                -webkit-line-clamp: 6;
                line-clamp: 6;
            }

            @include desktop-wide {
                -webkit-line-clamp: 5;
                line-clamp: 5;
            }
        }
    }

    @include hover-focus {
        & #{$this}__media img {
            transform: scale(1.05);
        }
    }
}
