.bookmarks-batch {
    @include desktop {
        --container-gap: #{col(1)};
    }

    @include desktop-wide {
        --container-gap: #{col(2)};
    }

    &__title {
        font-family: $ff-suisse;
        font-size: 39px;
        font-weight: $fw-medium;
        line-height: (64/39);

        @include desktop {
            font-size: 140px;
            line-height: 1;
        }

        em {
            font-family: $ff-tiempos;
            font-style: italic;
            font-weight: $fw-light;
        }
    }
}
