.FormSubmitButton,
.FormResetButton {
    @include btn;
    @include btn-large;

    grid-column: 1 / calc(var(--form-grid-columns) + 1);

    &.Form__Element {
        margin: 10px auto 0;
    }
}

.FormSubmitButton.Form__Element + .FormResetButton.Form__Element {
    margin-top: 0;
}

.FormResetButton {
    @include btn-danger;
}
