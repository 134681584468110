.section-iframe {
    $this: &;

    background: $color-extra-light-grey;

    &__container {
        --container-gap: 0;

        @include container;

        #{$this}--gaped & {
            --container-gap: 20px;

            @include desktop {
                --container-gap: #{col(1)};
            }
        }
    }

    &__title {
        margin-bottom: 24px;
        color: $color-main-dark;
        font-family: $ff-tiempos;
        font-size: 30px;
        font-style: italic;
        font-weight: $fw-light;
        line-height: 37px;
        text-align: left;

        @include desktop {
            margin-bottom: 32px;
            font-size: 42px;
            line-height: 51px;
        }
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 20px;
        padding-left: 20px;

        @include desktop {
            padding-right: col(1);
            padding-left: col(1);
        }

        #{$this}--gaped & {
            padding-right: 0;
            padding-left: 0;
        }

        .btn {
            margin-bottom: 24px;
            margin-left: auto;

            @include desktop {
                margin-bottom: 32px;
            }
        }
    }
}
