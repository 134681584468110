.article-related-services {
    $this: &;

    --article-related-services-vertical-gap: 16px;
    --article-related-services-horizontal-gap: 40px;

    &__title {
        font-size: 13px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(var(--article-related-services-vertical-gap) * -1 + 8px);

        #{$this}--mobile-center & {
            @include mobile-only {
                justify-content: center;
            }
        }
    }

    &__item {
        margin-top: var(--article-related-services-vertical-gap);
        margin-right: var(--article-related-services-horizontal-gap);

        #{$this}--mobile-center & {
            @include mobile-only {
                margin-right: calc(var(--article-related-services-horizontal-gap) / 2);
                margin-left: calc(var(--article-related-services-horizontal-gap) / 2);
            }
        }
    }

    &__link {
        position: relative;
        display: inline-block;
        border: 0;
        border-bottom: 1px solid;
        border-radius: 0;
        color: currentColor;
        font-family: $ff-tiempos;
        font-style: italic;
        text-decoration: none;
        transition: border-color 0.2s, opacity 0.2s;
    }

    &__link::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: calc(100% + 0.8em);
    }

    &__link:hover {
        opacity: 0.8;
        border-bottom-color: transparent;
    }

    &__link:active {
        opacity: 0.5;
    }

    &--mobile-center {
        @include mobile-only {
            text-align: center;
        }
    }
}
