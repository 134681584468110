.article-info {
    max-width: 480px;
    font-weight: $fw-light;

    @include desktop-medium {
        display: flex;
        align-items: center;
    }

    &__author {
        padding-right: get-offset(2);

        @include desktop-medium {
            width: 60%;
        }
    }

    &__additional {
        display: flex;
        align-items: center;
        line-height: 1.4;

        @include desktop-medium {
            display: block;
            width: 40%;
        }
    }

    &__author + &__additional {
        margin-top: 22px;

        @include desktop-medium {
            position: relative;
            margin-top: 0;
            padding-left: get-offset(2);
        }
    }

    &__author + &__additional::before {
        @include desktop-medium {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 1px;
            height: 20px;
            background: var(--theme-color-secondary);
        }
    }

    &__date {
        margin-right: 30px;

        @include desktop-medium {
            margin-right: 0;
        }
    }

    &__time-to-read {
        font-size: 13px;
    }
}
