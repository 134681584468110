.media-stat {
    $this: &;

    position: relative;
    z-index: 0;
    background: $color-extra-light-grey;

    &__container {
        @include container;

        display: flex;
        flex-direction: column;

        @include desktop {
            position: relative;
            flex-direction: row;
            max-width: col(8);
        }
    }

    &__link {
        width: fit-content;
        margin-top: 24px;

        @include desktop {
            position: static;
            clip-path: none;
            background: none !important;
            mix-blend-mode: difference;
            color: transparent !important;
            cursor: pointer;
        }

        @include hover-focus {
            mix-blend-mode: initial;

            &::after {
                background-color: $color-white;
                color: $color-main-dark;
            }
        }

        &::before {
            @include desktop {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                clip-path: none !important;
                display: block;
                width: 100%;
                height: 100%;
                background: none !important;
            }
        }

        &::after {
            @include desktop {
                content: 'Read more';
                position: absolute;
                top: 50%;
                left: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                transform: translate(-50%, -50%);
                width: 137px;
                height: 137px;
                padding: 0 20px;
                border: 1.5px solid currentColor;
                border-radius: 50%;
                color: $color-white;
                font-family: $ff-tiempos;
                font-size: 25px;
                font-style: italic;
                font-weight: $fw-light;
                line-height: (31/25);
                //here white is cause we use mix blend mode
                text-align: center;
                transition: background-color 0.3s linear, color 0.3s linear;
            }
        }
    }

    &__img {
        position: relative;
        overflow: hidden;
        width: 100%;
        max-height: 500px;

        @include desktop {
            width: 50%;
            max-height: 100%;
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 75%;

            @include desktop {
                padding-bottom: 100%;
            }
        }

        img {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin: 32px 0 0;
        padding-left: 0;
        font-family: $primary-ff;
        font-size: 16px;
        line-height: 25px;

        @include desktop {
            width: 50%;
            padding-left: col(1);
            font-size: 20px;
            line-height: 32px;
        }
    }

    &__big-text {
        font-size: 120px;
        font-weight: 600;
        line-height: 1;

        @include mobile-tiny-only {
            font-size: 100px;
            line-height: 1;
        }

        @include desktop-medium {
            font-size: 210px;
        }

        i {
            margin-left: -12px;
            font-family: $secondary-ff;
            font-weight: 300;

            @include desktop-medium {
                margin-left: -21px;
            }
        }
    }
}
