.section {
    &__title {
        @include title;

        margin-bottom: 30px;
        font-size: 30px;
        text-align: center;

        @include tablet {
            margin-bottom: 45px;
            font-size: 42px;
        }

        &--large {
            @include tablet {
                margin-bottom: 45px;
                font-size: 42px;
            }
        }
    }

    &__description {
        max-width: 360px;
        margin: 0 auto 20px;
        line-height: 1.75;
        text-align: center;

        @include tablet {
            margin-bottom: 45px;
        }
    }

    &--theme {
        background-color: var(--theme-color-primary);
        color: var(--theme-color-secondary);
    }
}
