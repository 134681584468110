.text-media-img {
    $text-media: '.text-media';
    $padding: col(1);

    position: absolute;
    width: 100%;
    height: 100%;

    &--inline {
        @include desktop {
            padding-left: col(1);

            #{$text-media}--media-right & {
                padding-right: col(1);
                padding-left: 0;
            }

            #{$text-media}--media-right & .share-btn-wrap {
                right: col(1);
            }
        }
    }

    & img {
        width: 100%;
        height: 100%;

        .article & {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    #{$text-media}__col--media-right &--inline {
        @include desktop {
            padding-right: col(1);
            padding-left: 0;
        }
    }

    #{$text-media}--full & {
        position: relative;

        &::before {
            content: '';
            display: block;
            padding-bottom: 56.25%;
        }

        & img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    #{$text-media}--full &--inline {
        @include desktop {
            width: calc(100% - 2 * #{$padding});
            margin-left: col(1);
            padding-right: 0;
            padding-left: 0;
        }

        .article & {
            @include desktop {
                width: 100%;
                margin-left: 0;
            }
        }
    }
}
