.nav {
    @include tablet {
        min-width: col(3);
    }

    &__breadcrumb {
        @include until-desktop {
            display: none;
        }
    }

    &__item {
        position: relative;

        & + & {
            @include desktop {
                margin-top: 1px;
            }
        }

        & [data-tippy-root] {
            width: 100%;
        }
    }
}
