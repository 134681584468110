.text-block-item {
    $text-media-text: '.text-media-text';
    $text-media-slider: '.text-media-slider';

    width: 100%;


    @include desktop-medium {
        width: col(3);
    }

    &__title {
        margin-bottom: 24px;
        font-size: 30px;
        line-height: 37px;

        @include desktop {
            margin-bottom: 20px;
            font-size: 37px;
            line-height: 1;
        }

        #{$text-media-slider} & {
            font-size: 40px;
            line-height: 48px;

            @include desktop-medium {
                max-width: 80%;
                font-size: 76px;
                line-height: 76px;
            }
        }

        a & {
            color: $color-main-dark;
        }
    }

    &--center {
        @include desktop {
            text-align: center;
        }
    }

    & >.btn {
        margin-bottom: 20px;
    }


    #{$text-media-text}--columns & {
        @include until-desktop {
            &:nth-child(1){
                .text-block-item__title {
                    margin-top: 0;
                }
            }
        }

        @include desktop {
            width: 40%;
        }
    }

}
