.section-form {
    $this: &;

    padding-top: 65px;
    padding-bottom: 65px;

    @include desktop {
        padding: col(1) 90px;
    }

    &__container,
    .kf-form-section,
    .EPiServerForms {
        --container-width: 776px;

        @include container;

        #{$this}--small & {
            --container-width: 480px;
        }
    }
}
