.theme-royal-blue {
    --theme-color-primary: #{$color-bright-blue};
    --theme-color-secondary: #{$color-white};
    --theme-color-accent: #{$color-dark-blue};
    --theme-color-accent-2: lighten(#{$color-dark-blue}, 10);
}

.theme-coral {
    --theme-color-primary: #{$color-red};
    --theme-color-secondary: #{$color-white};
    --theme-color-accent: #{$color-pink};
    --theme-color-accent-2: darken(#{$color-pink}, 10);
    --theme-btn-danger-bg-color: #{darken($color-red, 30)};
}

.theme-mustard {
    --theme-color-primary: #{$color-yellow};
    --theme-color-secondary: #{$color-main-dark};
    --theme-color-accent: #{$color-dusty-pink};
    --theme-color-accent-2: darken(#{$color-dusty-pink}, 10);
    --theme-article-btns-hover-color: #{$color-white};

    & figure,
    & .team-slider,
    & .generic-slider,
    & .text-media,
    & .texts-slider,
    & .richtext {
        --theme-color-primary: #{$color-main-dark};
    }
}

.theme-pure-gold {
    --theme-color-primary: #{$color-pure-gold};
    --theme-color-secondary: #{$color-main-dark};
    --theme-color-accent: #{$color-light-brown};
    --theme-color-accent-2: #{$color-light-gold};
    --theme-article-btns-hover-color: #{$color-white};
}

.theme-dusty-pink {
    --theme-color-primary: #{$color-dusty-pink};
    --theme-color-secondary: #{$color-main-dark};
    --theme-color-accent: #{$color-pink};
    --theme-color-accent-2: lighten(#{$color-pink}, 10);
    --theme-article-btns-hover-color: #{$color-white};
}

.theme-pink {
    --theme-color-primary: #{$color-pink};
    --theme-color-secondary: #{$color-main-dark};
    --theme-color-accent: #{$color-dusty-pink};
    --theme-color-accent-2: darken(#{$color-dusty-pink}, 10);
    --theme-article-btns-hover-color: #{$color-white};
}

.theme-peacost-blue {
    --theme-color-primary: #{$color-dark-blue};
    --theme-color-secondary: #{$color-white};
    --theme-color-accent: #{$color-bright-blue};
    --theme-color-accent-2: lighten(#{$color-bright-blue}, 10);
}

.theme-dark {
    --theme-color-primary: #{$color-main-dark};
    --theme-color-secondary: #{$color-white};
    --theme-input-border-color: transparent;
    --theme-btn-bg-color: #{$color-white};
    --theme-btn-hover-color: #{$color-white};
    --theme-btn-hover-bg-color: #{$color-main-dark};
    --theme-btn-color: #{$color-main-dark};
    --theme-range-dot-color: #{$color-white};
}

.theme-light {
    --theme-color-primary: #{$color-white};
    --theme-color-secondary: #{$color-main-dark};
    --theme-input-border-color: #{$color-meta-grey};
    --theme-range-color: #{$color-main-dark};
    --theme-range-focus-color: #{$color-white};
    --theme-range-focus-outline-color: #{$color-main-dark};
    --theme-article-btns-hover-color: #{$color-white};
}
