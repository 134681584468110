.Form__NavigationBar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 40px;
}

.Form__NavigationBar__ProgressBar {
    flex-grow: 1;
    width: auto !important;
    border-color: transparent !important;

    @include mobile-only {
        order: -1;
        width: 100% !important;
        margin-bottom: 10px;
    }

    @include tablet {
        margin: 0 20px;
    }

    &--Text {
        display: block !important;
        font-weight: 700;
        text-align: center;
    }

    &--Progress {
        display: none !important;
    }
}

.Form__NavigationBar__Action {
    position: relative;
    display: inline-block;
    width: auto !important;
    max-width: 100% !important;
    height: auto !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background: none !important;
    border: 0 !important;
    border: 1px solid transparent !important;
    border-radius: 0 !important;
    color: inherit !important;
    cursor: pointer;
    transition: border 0.2s, opacity 0.2s;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        width: 8px;
        height: 8px;
        border: 1px solid;
        border-top: 0;
    }

    &:not(:disabled):hover {
        border-color: currentColor !important;
    }

    &:active {
        opacity: 0.6;
    }

    &:disabled {
        opacity: 0.6;
        color: inherit;
        cursor: default;
    }

    &.btnNext {
        margin-right: -20px;
        padding-right: 45px !important;
        padding-left: 20px !important;

        @include mobile-only {
            margin-left: auto;
        }

        &::before {
            right: 20px;
            transform: rotate(-45deg) translateY(-50%);
            border-left: 0;
        }
    }

    &.btnPrev {
        top: 0;
        margin-left: -20px;
        padding-right: 20px !important;
        padding-left: 45px !important;

        &::before {
            left: 20px;
            transform: rotate(45deg) translateY(-50%);
            border-right: 0;
        }
    }
}
