body {
    --grid-columns: 4;
    --container-gap: 30px;
    --container-width: 100vw;
    --offset: 10px;
    --media-height: 233px;
    --page-header-logo-size: 105px;
    --scrollbar: 0px; // stylelint-disable-line length-zero-no-unit
    --viewport-height: 100vh;

    /* colors */
    --color-black: #{$color-black};
    --color-tranparent-black: #{$color-tranparent-black};
    --color-white: #{$color-white};
    --color-main-dark: #{$color-main-dark};
    --color-bright-blue: #{$color-bright-blue};
    --color-red: #{$color-red};
    --color-brand-red: #{$color-brand-red};
    --color-yellow: #{$color-yellow};
    --color-light-brown: #{$color-light-brown};
    --color-extra-light-grey: #{$color-extra-light-grey};
    --color-meta-grey: #{$color-meta-grey};
    --color-middle-grey: #{$color-middle-grey};
    --color-light-grey: #{$color-light-grey};
    --color-light-grey2: #707070;
    --color-pink: #{$color-pink};
    --color-dark-blue: #{$color-dark-blue};
    --color-brown: #{$color-brown};
    --toastify-color-progress-light: #{$color-main-dark};

    @include mobile-tiny-only {
        --page-header-logo-size: 70px;
    }

    @include tablet {
        --grid-columns: 8;
        --ofset: 13px;
        --media-height: 350px;
    }

    @include desktop {
        --container-width: calc(100vw - var(--scrollbar));
        --grid-columns: 12;
        --offset: 16px;
        --container-gap: 0;
        --page-header-logo-size: #{col(1)};
    }

    @include desktop-wide {
        --container-width: 1920px;
    }
}
