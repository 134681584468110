.share-popup-list {
    $this: &;

    padding: $share-btn-gap;
    background-color: $share-btn-primary-color;

    &__btn {
        @include shareBtn;

        @include hover-focus {
            background-color: $share-btn-secondary-color;
            color: $share-btn-primary-color;
        }

        #{$this}--small & {
            width: $share-btn-size-small;
            height: $share-btn-size-small;
        }
    }

    &__item {
        .article & {
            padding-left: 0;
        }

        .icon {
            width: 25px;
            height: 25px;
        }
    }

    [data-placement='left'] &,
    [data-placement='right'] & {
        display: flex;
    }
}
