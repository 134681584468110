.i-slider {
    $arrowPadding: col(1);
    $article: '.article';

    position: relative;
    overflow: hidden;
    max-width: 100%;

    &__container {
        position: relative;
        width: 100%;
        height: 100%;

        &::before {
            @include desktop {
                content: '';
                display: block;
                padding-top: 56.25%;
            }

            #{$article} & {
                display: none;
            }
        }

        #{$article} & {
            --container-gap: 0;
        }
    }

    &__main-wrapper {
        width: 100%;
        height: 100%;

        @include desktop {
            position: absolute;
            top: 0;
            left: col(1);
            width: calc(100% - 2 * #{$arrowPadding});

            #{$article} & {
                position: static;
                left: 0;
                width: 100%;
            }
        }
    }

    &__inner-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__slider-container {
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        height: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    &__arrows {
        display: none;

        @include desktop {
            position: absolute;
            right: 32px;
            bottom: 32px;
            z-index: 3;
            display: flex;

            #{$article} & {
                @include desktop-medium {
                    top: 50%;
                }
            }
        }

        & > button {
            background: var(--color-white);
        }
    }

    &__arrow-next {
        margin-left: 15px;
        background: var(--color-white);
    }

    &__list {
        @include slider-fade;

        height: 100%;
    }

    &__card {
        overflow: hidden;
        min-width: 100%;
        max-width: 100%;

        @include mobile-only {
            height: 650px;
        }

        @include tablet {
            height: 750px;
        }

        @include desktop {
            height: 100%;
        }

        #{$article} & {
            margin-top: 0;
            padding-left: 0;

            @include desktop {
                height: 750px;
            }

            &::before {
                display: none;
            }
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background: var(--color-main-dark);
    }

    &__img {
        position: relative;
        flex-grow: 1;
        width: 100%;

        @include desktop {
            height: 100%;
        }

        & img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;

            @include desktop {
                position: static;

            }
        }

        #{$article} & {
            & img {
                @include desktop {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    &__content {
        clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 100%);
        width: 100%;
        height: auto;
        margin-top: -55px;
        padding: 44px 24px 30px;
        background: var(--color-main-dark);
        color: var(--color-white);

        --autoplay:4s;

        @include tablet {
            position: absolute;
            bottom: 0;
            z-index: 3;
            width: 100%;
        }

        @include desktop {
            clip-path: polygon(0 0, 90% 8%, 100% 100%, 0 100%);
            width: 50%;
            padding: 64px 64px 44px;
        }

        @include desktop-medium {
            width: 35%;
        }

        #{$article} & {
            @include tablet {
                position: static;
            }

            @include desktop {
                clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
                width: 100%;
                padding: 64px;
            }

            @include desktop-medium {
                width: 100%;
            }
        }
    }

    &__title {
        margin-bottom: 16px;
        font-size: 24px;
        font-weight: $fw-medium;
        line-height: 29px;

        @include desktop {
            font-size: 30px;
            line-height: 37px;
        }

        & i {
            font-family: $ff-tiempos;
            font-style: italic;
            font-weight: $fw-light;
        }
    }

    &__text {
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: $fw-light;
        line-height: 28px;

        @include desktop {
            margin-bottom: 20px;
        }
    }
}
