.footer {
    $this:&;

    position: relative;
    padding: 25px $default-mobile-side-padding 90px;
    background: var(--color-main-dark);
    color: var(--color-white);

    @include desktop {
        padding: 100px col(1);
    }

    &__row {
        display: flex;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
            justify-content: space-between;
        }


        &--privacy {
            @include desktop {
                margin-top: -22px;
                margin-bottom: 22px;
            }
        }
    }

    &__col {
        width: 100%;
        padding-bottom: $default-mobile-side-padding;

        @include desktop {
            width: col(2);
            padding-bottom: 0;
        }

        #{$this}__row--privacy & {
            @include mobile-only {
                padding-bottom: 10px;
            }
        }

        &--logo {
            padding-bottom: 60px;


            @include desktop {
                width: col(2);
                padding-bottom: 0;
            }
        }

        &--socials {
            @include desktop {
                width: 140px;
            }

            @include desktop-medium {
                width: col(2);
            }

        }

        &--nav {
            @include desktop {
                flex-grow: 1;
                padding-left: col(1);
            }
        }

    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50vw);
        width: 100vw;
        height: 100%;
        background: inherit;
        z-index: -1;
    }





}
