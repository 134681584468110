.spinner {
    $this: &;

    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: $layer-element-spinner;
    width: 100%;
    height: 100%;
    mix-blend-mode: difference;
    transition: visibility 0.2s linear, opacity 0.2s linear;

    &__overlay {
        height: 100%;
        background-color: transparent;
        transition: background-color 0.3s linear;

        #{$this}.is-active & {
            background-color: rgba($color-light-grey, 0.1);
        }
    }

    &__icon {
        $size: 50px;

        position: absolute;
        top: calc(50% - #{$size / 2});
        left: calc(50% - #{$size / 2});
        display: none;
        width: $size;
        height: $size;
        mix-blend-mode: difference;
        color: var(--theme-color-accent);
        fill: none;
        stroke: currentColor;

        #{$this}.is-active & {
            display: block;
        }
    }

    .circle {
        @keyframes rotate {
            0% {
                stroke-dashoffset: 0;
            }

            100% {
                stroke-dashoffset: 126;
            }
        }

        animation: rotate 2s ease both infinite;
    }

    &.is-active {
        opacity: 1;
        visibility: visible;
    }
}
