.privacy-nav {
    width: 100%;
    padding-bottom: 80px;

    @include desktop {
        padding-bottom: 30px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;

        @include desktop {
            flex-direction: column;
            align-items: flex-end;
        }

        @include desktop-wide {
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
        }
    }
}

.privacy-item {
    color: var(--color-middle-grey);
    font-size: 14px;
    line-height: 16px;

    & + & {
        position: relative;
        margin-left: 12px;

        &::before {
            content: '/';
            position: absolute;
            top: 0;
            left: -8px;
        }
    }

}

.privacy-link {
    @include link-reset;

    color: var(--color-middle-grey);
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
}
