.btn {
    @include btn;

    &--danger {
        @include btn-danger;
    }

    &--large {
        @include btn-large;
    }

    &--white {
        background: var(--color-main-dark);
        color: var(--color-main-dark);

        &::before {
            content: '';
            position: absolute;
            top: 1px;
            left: 1px;
            z-index: -1;
            clip-path: var(--btn-clip-path);
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            background-color: var(--color-white);
        }

        @include hover-focus {
            color: var(--color-white);

            &::before {
                content: '';
                position: absolute;
                top: 1px;
                left: 1px;
                z-index: -1;
                clip-path: var(--btn-clip-path);
                width: calc(100% - 2px);
                height: calc(100% - 2px);
                background-color: var(--color-main-dark);
            }
        }
    }

    & .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        fill: var(--color-white);
    }
}

.btn-download {
    $this: &;

    --btn-download-clip-path: polygon(4% 5%, 100% 8%, 92% 96%, 8% 96%);

    @include link-reset;

    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--theme-color-accent);
    color: var(--theme-color-secondary);
    font-size: 16px;
    font-weight: $fw-medium;
    line-height: 21px;
    white-space: pre;

    @include mobile-tiny-only {
        font-size: 14px;
    }

    @include desktop {
        clip-path: var(--btn-download-clip-path);
        align-self: center;
        margin-right: 25px;
        padding: 20px 30px;
        justify-self: flex-end;
    }

    &::before {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-color: var(--theme-color-accent);

        @include desktop {
            clip-path: var(--btn-download-clip-path);
        }
    }

    .icon {
        display: none;

        @include desktop {
            display: block;
            margin-right: 5px;
            transition: transform $transition-default, opacity 0.3s linear, width 0.3s ease;
        }
    }

    @include hover-focus {
        background-color: var(--theme-color-accent);
        color: var(--theme-color-accent);

        &::before {
            top: 1px;
            left: 1px;
            z-index: -1;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            background-color: var(--theme-color-secondary);
        }
    }

    @include active() {
        opacity: 0.7;
    }

    & i,
    em {
        font-family: $ff-tiempos;
        font-style: italic;
        font-weight: $fw-light;
    }
}

.btn-round {
    $this: &;

    @include focusBoxShadowDark;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    background: none;
    border: 1px solid currentColor;
    border-radius: 50%;
    color: var(--theme-color-secondary, var(--color-black));
    font-family: $ff-tiempos;
    font-size: 22px;
    font-style: italic;
    font-weight: $fw-light;
    line-height: 24px;
    text-decoration: none;
    cursor: pointer;

    @include desktop {
        width: 162px;
        height: 162px;
        font-size: 39px;
        line-height: 42px;
    }

    @include hover-focus {
        & > span {
            transform: scale(1.25);
            transition: transform $transition-motion;
        }
    }

    &--medium {
        width: 142px;
        height: 142px;
        padding: 18px;
        font-size: 20px;
        line-height: 1.2;
    }

    &--large {
        width: 192px;
        height: 192px;
        font-size: 22px;
        line-height: 24px;
    }

    &--white {
        @include focusBoxShadowLight;

        border: 1px solid var(--color-white);
        color: var(--color-white);
    }

    & span {
        word-break: break-word;
    }
}

.btn-square {
    @include focusBoxShadowDark;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: var(--color-main-dark);
    border: none;
    color: var(--color-white);
    cursor: pointer;
    transition: background-color 0.3s linear, color 0.3s linear;

    @include desktop {
        width: 80px;
        height: 80px;
    }

    @include active-hover-focus {
        background-color: var(--color-white);
        color: var(--color-main-dark);

        & > .icon {
            transform: scale(1.16);
        }
    }

    & .icon {
        transition: transform 0.3s ease;

        @include mobile-only {
            width: 20px;
            height: 20px;
        }
    }
}

.btn-arrow {
    $this: &;

    @include focusBoxShadowDark;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background: none;
    border: 1px solid var(--theme-color-primary);
    border-radius: 50%;
    color: var(--color-black);
    cursor: pointer;
    transition: opacity 0.2s;

    &:active {
        opacity: 0.7;
    }

    @include hover-focus {
        background: var(--theme-color-primary);

        & > .icon {
            fill: var(--color-white);
        }
    }

    &--themed {
        border-color: var(--theme-color-accent);
        color: var(--theme-color-accent);
    }

    &--white-black {
        background: $color-white;
        border: none;

        @include hover-focus {
            background: $color-black;

            & .icon {
                fill: $color-white;
            }
        }

        & .icon {
            fill: $color-black;
        }
    }

    & .icon {
        transition: transform $transition-motion;
        fill: var(--theme-color-primary);
    }
}
