.component-title {
    $recommended: '.recommended-reading-page';

    #{$recommended} & {
        @include desktop {
            text-align: center;
            font-size: 38px;
        }
    }

    margin-bottom: 34px;
    font-family: $ff-tiempos;
    font-size: 24px;
    font-style: italic;
    font-weight: $fw-light;
    line-height: (58/42);

    @include desktop {
        font-size: 42px;
    }
}
