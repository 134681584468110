.generic-slider {
    $this: &;

    overflow: hidden;
    max-width: 100%;

    &__head {
        position: relative;
        margin-bottom: 34px;
    }

    &__inner {
        @include until-desktop {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
        @include desktop {
            padding-right: col(1);
            padding-left: col(1);
        }

        @include desktop-medium {
            padding-right: col(2);
            padding-left: col(2);
        }
    }

    &__controls {
        grid-template-columns: auto auto;
        display: grid;
        width: max-content;
        gap: 16px;

        @include until-desktop {
            order: 5;
            margin-top: 20px;
            margin-right: auto;
            margin-left: auto;
        }

        @include desktop {
            margin-bottom: 20px;
        }

        #{$this}--xl & {
            @include until-desktop {
                margin-left: 0;
            }
        }

        #{$this}.is-not-enough & {
            display: none;
        }
    }

    &__title {
        font-family: $ff-tiempos;
        font-size: 24px;
        font-style: italic;
        font-weight: $fw-light;
        line-height: (58/42);

        @include desktop {
            font-size: 42px;
        }
    }

    &__slider-container {
        max-width: col(2);
        margin-right: auto;
        margin-left: auto;

        @include tablet {
            max-width: 100%;
        }

        #{$this}--xl & {
            max-width: 100%;
        }
    }

    &__list {
        display: flex;
    }

    &__card {
        min-width: col(2);
        max-width: col(2);
        padding-right: 30px;

        @include desktop {
            box-sizing: content-box;
            min-width: col(1.75);
            max-width: col(1.75);
            padding-right: col(0.25);
        }

        #{$this}--xl & {
            min-width: calc(#{col(4)} - var(--container-gap) * 2);
            max-width: calc(#{col(4)} - var(--container-gap) * 2);

            @include tablet {
                min-width: 400px;
                max-width: 400px;
            }
        }
    }
}
