.filter-cards-grid {
    $this: &;

    position: relative;

    @include desktop-medium {
        padding-right: col(1);
        padding-left: col(1);
    }

    &__items {
        transition: opacity 0.3s linear;

        #{$this}.is-loading & {
            opacity: 0.2;
            pointer-events: none;
        }
    }

    .modal & {
        padding-right: 0;
        padding-left: 0;
    }
}
