$color-black: #000000;
$color-tranparent-black: rgba(0, 0, 0, 0.85);
$color-white: #FFFFFF;
$color-main-dark: #191919;
$color-bright-blue: #0066FF;
$color-red: #FD4954;
$color-brand-red: #D0103A;
$color-yellow: #E9B94D;
$color-light-brown: #DEBF8A;
$color-extra-light-grey: #F4F4F5;
$color-meta-grey: #E9EAEB;
$color-middle-grey: #999999;
$color-light-grey: #818182;
$color-pink: #FB8BA8;
$color-dusty-pink: #EDC7C5;
$color-dark-blue: #083A5A;
$color-brown: #D0835A;
$color-pure-gold: #BE7707;
$color-light-gold: #D8AD6A;
