@mixin shareBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $share-btn-size;
    height: $share-btn-size;
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: $share-btn-secondary-color;
    cursor: pointer;
    transition: background-color 0.2s, fill 0.2s, opacity 0.2s;

    &:active {
        opacity: 0.8;
    }
}

.share-btn {
    $this: &;

    padding: $share-btn-gap;
    background-color: $share-btn-primary-color;
    transition: background-color 0.2s;

    &__wrapper {
        position: relative;
    }

    &__toggle-btn {
        @include shareBtn;

        background-color: $share-btn-primary-color;

        .article-btns & {
            color: var(--theme-color-secondary);
        }

        #{$this}--active & {
            position: relative;
            z-index: calc(var(--share-btn-popup-z-index) + 1);
            background-color: $share-btn-secondary-color;
            color: $share-btn-primary-color;
        }

        #{$this}--empty:not(#{$this}--active) & {
            background-color: transparent;
        }

        #{$this}--small & {
            width: $share-btn-size-small;
            height: $share-btn-size-small;
        }
    }

    &--empty {
        &:not(#{$this}--active) {
            background-color: transparent;
        }
    }
}
