/* general mixins and helpers */

@mixin reset-list() {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin reset-button() {
    background-color: transparent;
    border: none;
}

@mixin objectFitImageParent($fit: cover) {
    img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: $fit;

        @content;
    }
}

@mixin focusBoxShadowDark {
    [data-whatintent='keyboard'] &:focus,
    [data-whatinput='keyboard'] &:focus {
        box-shadow: $box-shadow-dark;
        outline: none;
        appearance: none;
    }
}

@mixin focusBoxShadowLight {
    [data-whatintent='keyboard'] &:focus,
    [data-whatinput='keyboard'] &:focus {
        box-shadow: $box-shadow-light;
        outline: none;
        appearance: none;
    }
}

@mixin container() {
    max-width: var(--container-width);
    margin-right: auto;
    margin-left: auto;
    padding-right: var(--container-gap);
    padding-left: var(--container-gap);
}

@mixin link-reset() {
    color: inherit;
    text-decoration: none;
}

@mixin hover-focus() {
    [data-whatintent='mouse'] &:hover,
    [data-whatintent='keyboard'] &:focus,
    [data-whatinput='keyboard'] &:focus {
        outline: none;
        @content;
    }
}

@mixin active-hover-focus() {
    .is-active,
    [data-whatintent='mouse'] &:hover,
    [data-whatintent='keyboard'] &:focus,
    [data-whatinput='keyboard'] &:focus {
        @content;
    }
}

@mixin not-touch() {
    [data-whatintent='mouse'] &,
    [data-whatintent='keyboard'] & {
        @content;
    }
}

@mixin hover() {
    [data-whatintent='mouse'] &:hover {
        @content;
    }
}

@mixin active() {
    [data-whatintent='mouse'] &:active {
        @content;
    }
}

@function get-offset($factor) {
    @return calc(var(--offset) * #{$factor});
}

@mixin visually-hidden() {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
}

@mixin pretty-scroll($color: $color-main-dark, $opacity: 0.7) {
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: rgba($color, $opacity);

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-thumb {
        position: relative;
        background: rgba($color, $opacity);
        border: 0 none rgba($color, $opacity);

        &:active,
        &:hover {
            background: rgba($color, 1);
        }
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border: 0 none transparent;

        &:active,
        &:hover {
            background: transparent;
        }
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }
}

@mixin title {
    font-weight: $fw-medium;
    line-height: 1.2;

    i {
        font-family: $ff-tiempos;
        font-weight: $fw-light;
    }
}

@mixin slider-fade($speed: 0.6) {
    grid-template-areas: 'slide';
    display: grid;
    transform: none !important;

    > * {
        opacity: 0;
        grid-area: slide;
        transition: opacity #{$speed}s ease;
    }

    > *.glide__slide--active {
        opacity: 1;
        z-index: 1;
        transition: opacity #{$speed}s ease;
    }
}

@mixin input {
    display: block;
    width: 100%;
    height: 64px;
    padding: 22px;
    background-color: var(--color-white);
    border: none;
    color: var(--color-main-dark);
    font-family: $ff-suisse;
    font-size: 16px;
    line-height: 1;
    outline: none;
}

@mixin input-form {
    --input-border-color: #{$color-meta-grey};

    border: 1px solid var(--theme-input-border-color, var(--input-border-color));

    &:focus {
        border-color: var(--theme-input-focus-border-color, var(--color-main-dark));
        box-shadow: var(--theme-input-focus-box-shadow, 0 0 0 2px #{$color-white});
    }
}

@mixin textarea {
    @include input;
    @include input-form;

    min-height: 179px;
    resize: none;

    @include desktop {
        min-height: 214px;
    }
}

@mixin form {
    --form-gap: 16px;
    --form-grid-columns: 1;

    grid-template-columns: repeat(var(--form-grid-columns), 1fr);
    display: grid;
    gap: var(--form-gap);
}

@mixin form-2cols {
    @include tablet {
        --form-grid-columns: 2;
        --form-gap: 24px;
    }
}

@mixin form-3cols {
    @include desktop {
        --form-grid-columns: 3;
        --form-gap: 24px;
    }
}

@mixin form-4cols {
    @include form-2cols;

    @include desktop {
        --form-grid-columns: 4;
    }
}

@mixin btn {
    --btn-clip-path: polygon(3% 7%, 100% 7%, 91% 90%, 8% 95%);

    position: relative;
    z-index: 1;
    clip-path: var(--btn-clip-path);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding: 0 40px;
    background: var(--theme-btn-bg-color, var(--color-main-dark));
    border: 0;
    color: var(--theme-btn-color, var(--color-white));
    font-family: $ff-tiempos;
    font-size: 18px;
    font-style: italic;
    font-weight: $fw-light;
    text-decoration: none;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        z-index: -1;
        clip-path: var(--btn-clip-path);
        width: 100%;
        height: 100%;
        background-color: var(--theme-btn-bg-color, var(--color-main-dark));
    }

    @include hover-focus {
        &:not(:disabled) {
            color: var(--theme-btn-hover-color, var(--color-main-dark));
        }

        &:not(:disabled)::before {
            content: '';
            position: absolute;
            top: 1px;
            left: 1px;
            z-index: -1;
            clip-path: var(--btn-clip-path);
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            background-color: var(--theme-btn-hover-bg-color, var(--color-white));
        }
    }

    @include active() {
        opacity: 0.7;
    }
}

@mixin btn-danger {
    background-color: var(--theme-btn-danger-bg-color, #{$color-red});

    &::before {
        background-color: var(--theme-btn-danger-bg-color, #{$color-red});
    }
}

@mixin btn-large {
    clip-path: var(--btn-clip-path);
    min-width: 166px;
    font-size: 18px;
}

@mixin input-range {
    display: block;
    width: 100%;
    height: 3px;
    background-color: var(--theme-range-color, #{$color-white});
    outline: 0;
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
        width: 20px;
        height: 20px;
        background-color: var(--theme-range-dot-color, var(--color-main-dark));
        border-radius: 50%;
        cursor: pointer;
        transition: 0.2s ease;
        -webkit-appearance: none;
    }

    &::-webkit-slider-thumb:active {
        transform: scale(1.2);
    }

    [data-whatinput='keyboard'] &:focus {
        background-color: var(--theme-range-focus-color, var(--color-main-dark));
        box-shadow: 0 0 0 2px var(--theme-range-focus-outline-color, #{$color-white});
    }
}

@mixin select {
    --select-arrow-width: 18px;
    --select-arrow-height: 18px;
    --select-arrow-position-right: 15px;

    position: relative;
    padding-right: 25px;
    background-position: center right var(--select-arrow-position-right);
    background-repeat: no-repeat;
    background-size: var(--select-arrow-width) var(--select-arrow-height);
    border: 1px solid var(--input-border-color, transparent);
    border-radius: 0;
    font-weight: $fw-light;
    text-align: left;
    cursor: pointer;
    background-image: url($icon-arrow-down);
    appearance: none;

    @include desktop {
        padding-right: 42px;
    }
}

@mixin checkbox {
    $this: &;

    --checkbox-size: 36px;

    position: relative;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    background-color: $color-white;
    border: 1px solid var(--theme-input-border-color, transparent);
    border-radius: 0;
    cursor: pointer;
    outline: none;
    appearance: none;

    &::after {
        content: '';
        position: absolute;
        top: calc(var(--checkbox-size) / 2);
        left: calc(var(--checkbox-size) / 2);
        opacity: 0;
        display: block;
        transform: translate(-50%, -50%) rotate(45deg) scale(0.5);
        transform-origin: center right;
        width: 8px;
        height: 16px;
        border-right: 2px solid $color-main-dark;
        border-bottom: 2px solid $color-main-dark;
        transition: opacity 0.2s, transform 0.3s;
    }

    &:checked::after {
        opacity: 1;
        transform: translate(-50%, -50%) rotate(45deg) scale(1);
    }

    [data-whatintent='keyboard'] &:focus,
    [data-whatinput='keyboard'] &:focus {
        border-color: var(--theme-input-focus-border-color, var(--color-main-dark));
        box-shadow: 0 0 0 2px var(--theme-input-focus-outline-color, #{$color-white});
    }
}

@mixin richtext {
    a:not([class]) {
        color: var(--theme-color-primary);
        text-decoration: none;
        transition: opacity 0.2s;

        @include hover-focus {
            text-decoration: underline;
        }

        &:active {
            opacity: 0.6;
        }

        .theme-mustard & {
            text-decoration: underline;
        }
    }

    iframe {
        max-width: 100%;
    }

    ul,
    ol,
    table,
    iframe,
    hr {
        margin: 30px 0;
        padding: 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        // TODO
        margin-top: 40px;
        font-family: $ff-tiempos;
        font-style: italic;
        font-weight: $fw-light;
        line-height: 1.25;

        a & {
            color: $color-main-dark;
        }
    }

    h1,
    h2 {
        // TODO
        margin-bottom: 30px;
    }

    h3,
    h4,
    h5 {
        // TODO
        margin-bottom: 20px;
    }

    h6 {
        // TODO
        margin-bottom: 15px;
    }

    h1 {
        // TODO
        font-size: 35px;

        @include desktop-wide {
            font-size: 56px;
        }
    }

    h2 {
        // TODO
        font-size: 24px;

        @include tablet {
            font-size: 39px;
        }
    }

    h3 {
        // TODO
        font-size: 20px;

        @include tablet {
            font-size: 30px;
        }
    }

    h4 {
        // TODO
        font-size: 20px;

        @include tablet {
            font-size: 24px;
        }
    }

    h5,
    h6 {
        // TODO
        font-size: 20px;
    }

    ol,
    ul {
        font-size: 20px;
        line-height: 1.6;
    }

    ol {
        list-style: none;
        counter-reset: custom-counter;

        li {
            position: relative;
            padding-left: 30px;
            counter-increment: custom-counter;

            &::before {
                content: counter(custom-counter) '. ';
                position: absolute;
                left: 0;
                color: var(--button-color);
            }
        }
    }

    ul {
        list-style: none;

        li {
            position: relative;
            padding-left: 20px;

            &::before {
                content: '';
                position: absolute;
                top: 9px;
                left: 0;
                clip-path: polygon(90% 15%, 80% 90%, 15% 100%, 0% 15%);
                width: 9px;
                height: 9px;
                background: var(--theme-color-primary);
            }
        }
    }

    li + li {
        margin-top: 16px;
    }

    b,
    strong {
        font-weight: $fw-bold;
    }

    img {
        display: block;
        margin-top: get-offset(4);
        margin-bottom: get-offset(4);
    }

    p {
        margin: 30px 0;
        line-height: 1.56;
    }

    blockquote p {
        margin: 0;
        line-height: 1;
    }

    figure {
        margin-top: get-offset(4);
    }

    figure + * {
        margin-top: get-offset(4);
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}
