/*  reset default styles */

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

ul {
    @include reset-list;
}

img {
    vertical-align: top;
}

.article-title {
    font-size: 40px;
    font-weight: $fw-medium;
    line-height: 1.25;

    @include desktop-wide {
        font-size: 49px;
    }

    i {
        font-family: $ff-tiempos;
        font-weight: $fw-light;
    }
}
