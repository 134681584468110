.section-video {
    $this: &;

    position: relative;
    padding-bottom: 30px;

    @include desktop {
        padding-bottom: col(1);
    }

    &__bg-element {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: calc(50% + 30px / 2);
        padding-bottom: col(1);
        background: var(--theme-color-primary);

        @include desktop {
            height: calc(50% + #{col(1) / 2});
        }
    }

    &__container {
        @include container;

        position: relative;

        @include desktop {
            padding-right: col(1);
            padding-left: col(1);
        }
    }
}
