.search-modal {
    --theme-color-primary: var(--color-main-dark);
    --theme-color-secondary: var(--color-white);
    --theme-color-accent: var(--color-white);

    background-color: var(--theme-color-primary);

    &__form {
        position: relative;
        margin-top: calc(var(--viewport-height) / 5);
        padding-top: 60px;

        @include mobile-wide {
            margin-top: calc(var(--viewport-height) / 4);
        }

        @include desktop {
            margin-top: 0;
            padding-top: 160px;
        }
    }

    &__wrapper {
        [data-tippy-root] {
            width: 100%;
        }
    }

    &__input {
        $centering-gap: 160px;
        $tablet-centering-gap: 320px;

        width: 100%;
        margin-left: 2px;
        background-color: var(--color-main-dark) !important;
        border: 0 solid transparent;
        border-image-width: 0;
        color: var(--theme-color-secondary);
        font-family: $ff-suisse;
        font-size: 30px;
        font-weight: $fw-medium;
        text-align: center;
        outline: none;
        appearance: none;
        caret-shape: underscore;
        caret-color: currentColor;

        @include mobile-tiny-only {
            font-size: 29px;
        }

        @include tablet {
            font-size: 60px;
        }

        @include desktop {
            font-size: 120px;
            text-align: left;
        }

        @include desktop-wide {
            font-size: 153px;
        }

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            -webkit-appearance: none;
        }

        &::placeholder {
            @include mobile-only {
                transform: translateX(#{$centering-gap * 0.5});
            }

            @include tablet-only {
                transform: translateX(#{$tablet-centering-gap * 0.5});
            }
        }

        &.without-text {
            @include mobile-only {
                padding-right: $centering-gap;
            }

            @include tablet-only {
                padding-right: $tablet-centering-gap;
            }

            @supports (-moz-appearance:none) {
                padding-right: 0;
            }
        }
    }

    &__submit {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 165px;
        height: 165px;
        margin-top: calc(var(--viewport-height) / 4);
        background-color: transparent;
        border: 1px solid $color-white;
        border-radius: 50%;
        color: $color-white;
        font-family: $ff-tiempos;
        font-size: 39px;
        font-style: italic;
        font-weight: $fw-light;
        line-height: 1;
        transition: color 0.3s linear, background-color 0.3s linear;

        @include until-desktop {
            margin-right: auto;
            margin-left: auto;
        }

        @include desktop {
            margin-top: 305px;
        }

        @include hover-focus {
            background-color: $color-white;
            color: $color-main-dark;
        }
    }
}
