.share-btn-wrap {
    position: relative;

    .iframe-component & {
        .share-btn {
            position: absolute;
            top: 100%;
            right: 0;
            bottom: auto;

            @include desktop {
                top: auto;
                bottom: 0;
            }
        }

        .content-components & {
            @include until-desktop {
                margin-bottom: calc(#{$share-btn-size} + 40px);
            }
        }
    }

    .text-media-img & {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .section-video & {
        position: absolute;
        right: var(--container-gap);
        bottom: 0;

        @include desktop {
            right: col(1);
        }
    }
}
