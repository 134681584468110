.text-media-text {
    $text-media: '.text-media';

    &--columns {
        @include desktop {
            display: flex;
            justify-content: space-between;
        }

        @include desktop-medium {
            padding-right: col(1.5);
            padding-left: col(1.5);

        }

        #{$text-media}--centered-texts & {
            @include desktop-medium {
                padding-right: col(1);
                padding-left: col(1);
            }
        }
    }

    #{$text-media}--only-texts & {
        @include desktop {
            padding-top: col(1);
            padding-bottom: col(1);
        }
    }
}


