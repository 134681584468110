.section-menu {
    $this: &;

    &--accent-bg {
        background: var(--theme-color-accent);
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        min-height: 482px;
        padding: col(1) $default-mobile-side-padding;

        @include desktop {
            justify-content: center;
            min-height: 443px;
            margin: 0 auto;
        }

    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;

        @include desktop {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
        }

        &--down {
            margin-bottom: 0;
        }

    }

    &__item {
        @include until-desktop {
            & + & {
                margin-top: 24px;
            }
        }

        @include desktop {
            & + & {
                margin-top: 0;
                margin-left: 64px;
            }
        }

        #{$this}__list + #{$this}__list & {
            @include until-desktop {
                margin-top: 16px;
            }
        }

    }

    &__link {
        @include link-reset();

        display: inline-block;
        color: var(--theme-color-secondary);
        font-family: $ff-suisse;
        font-size: 28px;
        font-weight: $fw-medium;
        line-height: 37px;
        text-align: center;

        @include desktop {
            font-size: 42px;
            line-height: 54px;
        }

        #{$this}__list--secondary & {
            font-size: 20px;
            line-height: 26px;

            @include desktop {
                font-size: 28px;
                line-height: 37px;
            }
        }

    }


}
