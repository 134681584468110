.FormFileUpload {
    $btnWidth: 166px;
    $btnHeight: 50px;

    position: relative;
    display: flex;
    flex-direction: column;

    .FormFileUpload__PostedFile {
        display: none;
    }

    .input-file-upload-text {
        opacity: 0.8;
        font-size: 14px;
        line-height: 1.4;

        &:not(:empty) {
            margin-top: 5px;
        }
    }

    input[type='file'] {
        position: absolute;
        top: 34px;
        overflow: hidden;
        width: $btnWidth;
        height: $btnHeight;
        background: none;
        border: 1px solid transparent;
        border-radius: 0;
        font-size: 0;
        text-indent: -9999px;
        cursor: pointer;
        outline: none;
        transition: border 0.2s, box-shadow 0.2s;
        appearance: none;

        @include hover-focus {
            border-color: var(--theme-input-focus-border-color, var(--color-main-dark));
            box-shadow: var(--theme-input-focus-box-shadow, 0 0 0 2px #{$color-white});
        }

        &:active {
            border-color: transparent;
        }

        [data-whatintent='keyboard'] &:focus,
        [data-whatinput='keyboard'] &:focus {
            border-color: var(--theme-input-focus-border-color, var(--color-main-dark));
            box-shadow: var(--theme-input-focus-box-shadow, 0 0 0 2px #{$color-white});
        }
    }

    label {
        content: '';
        position: relative;
        display: inline-block;
        width: max-content;
        padding-bottom: $btnHeight + 5px;

        &::after {
            content: 'Upload file';
            position: absolute;
            display: block;
            width: max-content;
            width: $btnWidth;
            height: $btnHeight;
            margin-top: 5px;
            padding-left: 60px;
            background-color: var(--color-white);
            background-position: left 30px center;
            background-repeat: no-repeat;
            background-size: 20px;
            border: 1px solid var(--theme-input-border-color, #E9EAEB);
            color: var(--color-main-dark);
            line-height: $btnHeight;
            transition: opacity 0.2s;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.9 13.038l3.6-3.6v15.5a.45.45 0 00.9 0v-15.5l3.6 3.6a.4.4 0 00.6 0 .399.399 0 000-.6l-4.4-4.4a.4.4 0 00-.1 0 .4.4 0 00-.5 0l-4.3 4.4a.4.4 0 000 .6.4.4 0 00.6 0z'/%3E%3Cpath d='M19.9 7.4A7.7 7.7 0 0012.3.9a7.8 7.8 0 00-7.4 5.3 2.7 2.7 0 00-.6-.1 2.5 2.5 0 00-2.4 1.6 2.2 2.2 0 000 1.4 2.4 2.4 0 00.1.5 3.5 3.5 0 00-.7.6 3.098 3.098 0 00-.5.5 3.6 3.6 0 00-.7 2.2 4 4 0 004 3.8h6a.4.4 0 100-.7h-6a3.101 3.101 0 01-3.2-3.1 2.9 2.9 0 01.6-1.7 2.5 2.5 0 01.3-.5 3 3 0 011-.6.4.4 0 00.2-.2.4.4 0 000-.4 1.6 1.6 0 01-.4-.6 1.5 1.5 0 010-1A1.7 1.7 0 014.3 7a1.8 1.8 0 01.5 0l.2.1a.4.4 0 00.3 0 .4.4 0 00.3-.2 7 7 0 016.7-5.1 6.9 6.9 0 017 6 .4.4 0 00.3.4 4 4 0 013.6 4 3.9 3.9 0 01-1.1 2.6 3.999 3.999 0 01-3 1.3H14a.4.4 0 100 .7h5.2a4.9 4.9 0 003.5-1.4 4.6 4.6 0 001.3-3.3 4.8 4.8 0 00-4-4.6l-.1-.1z'/%3E%3C/svg%3E");
        }
    }
}
