.breadcrumb {
    position: relative;
    display: flex;
    background-color: var(--theme-color-accent);
    color: var(--theme-color-secondary);

    &__title {
        display: flex;
        align-items: center;
        padding: 20px;
        font-size: 20px;
        font-style: $fw-light;

        @include desktop {
            flex-grow: 1;
            padding: 25px;
            font-size: 24px;
        }
    }
}
