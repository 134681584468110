textarea {
    @include textarea;
}

.FormTextbox {
    position: relative;
    display: flex;
    flex-direction: column;

    &.ValidationRequired[data-f-modifier='textarea']::before {
        bottom: 198px;
    }

    &.ValidationRequired::before {
        content: '';
        position: absolute;
        right: 5px;
        bottom: 48px;
        z-index: 1;
        clip-path: polygon(90% 15%, 80% 90%, 15% 100%, 0% 15%);
        width: 9px;
        height: 9px;
        background: var(--color-main-dark);
    }
}
