.EPiServerForms .Form__Element {
    margin: 0;
}

.EPiServerForms .FormRange .FormRange__Input {
    display: block;
    max-width: 100%;
}

.EPiServerForms .Form__Element .Form__Element__Caption {
    display: block;
}

.EPiServerForms .Form__Status .Form__Status__Message:empty {
    display: none;
}

.EPiServerForms .Form__Element .Form__Element__Caption:empty {
    display: none;
}

.EPiServerForms .FormChoice--Image .FormChoice--Image__Item {
    display: flex;
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
}

.EPiServerForms .FormCaptcha .FormTextbox__Input {
    width: 100%;
}

.EPiServerForms .Form__Element .Form__Element__ValidationError {
    color: #EA000E;
}
