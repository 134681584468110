.FormRange {
    position: relative;
    display: flex;
    flex-direction: column;

    .FormRange__Input {
        @include input-range;
    }

    .Form__Element__Caption {
        display: block;
        margin-bottom: 40px;
    }

    & output,
    .FormRange__Min,
    .FormRange__Max {
        position: absolute;
        bottom: 15px;
        opacity: 0.7;
        font-size: 12px;
    }

    .FormRange__Min {
        left: 0;
    }

    .FormRange__Max {
        right: 0;
    }

    &.Form__Element {
        margin: 20px 0;
    }

    & output {
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
    }
}
