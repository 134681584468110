.hero {
    $this: &;
    $image-offset: 35px;
    $gap-half: col(0.5);

    position: relative;
    overflow: hidden;
    background: var(--theme-color-primary);

    @include desktop {
        min-height: 100vh;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        @include desktop {
            justify-content: center;
            padding: col(1.7) col(1) col(1);
        }

        #{$this}--no-bg & {
            @include desktop {
                padding-bottom: 0;
            }
        }
    }

    &__text {
        position: relative;
        z-index: 2;
        margin-bottom: 60px;
        color: var(--theme-color-secondary);
        font-family: $ff-suisse;
        font-size: 16px;
        font-weight: $fw-light;
        line-height: 22px;

        @include desktop {
            width: col(5);
            margin-bottom: 0;
            padding-bottom: 30px;
            padding-left: col(1);
            font-size: 20px;
            line-height: 32px;
        }

        @include desktop-wide {
            width: col(4);
        }

        &--2cols {
            display: flex;
            flex-direction: column;

            @include desktop {
                display: flex;
                flex-direction: row;
                width: col(7);

                & p + p {
                    margin-left: $default-tablet-side-padding;
                }

                & div + div {
                    margin-left: $default-tablet-side-padding;
                }
            }

            @include until-desktop {
                & p + p {
                    margin-top: $default-mobile-side-padding;
                }

                & div + div {
                    margin-top: $default-mobile-side-padding;
                }
            }

        }

        #{$this}--home & {
            @include desktop {
                width: col(5);
            }

            @include desktop-wide {
                width: col(6);
            }
        }
    }

    &__image {
        position: relative;
        width: calc(100% + var(--container-gap));
        height: 238px;
        margin-top: -50px;
        margin-bottom: $gap-half;

        @include desktop {
            position: absolute;
            top: 0;
            left: col(4);
            width: col(10);
            height: 100%;
            margin-top: 0;
            margin-bottom: 0;
        }

        @include desktop-medium {
            left: 45%;
        }

        &--medium-height {
            @include desktop {
                height: 70%;

                & img {
                    clip-path: polygon(4% 0, 85% 0, 70% 75%, 0 50%);
                }
            }

            @include desktop-medium {
                & img {
                    clip-path: polygon(4% 0, 70% 0, 60% 75%, 0 50%);
                }
            }
        }

        & img {
            clip-path: polygon(0 9%, 100% 0%, 100% 100%, 7% 83%);
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include desktop {
                clip-path: polygon(4% 0, 100% 0, 67% 100%, 0 50%);
            }
        }

        #{$this}--home & {
            & img {
                clip-path: polygon(8% 12%, 100% 0%, 100% 100%, 0 100%);

                @include desktop {
                    clip-path: polygon(4% 0, 100% 0, 67% 100%, 0 50%);
                }
            }
        }

        #{$this}--medium-height &--medium-height {
            @include desktop {
                & img {
                    clip-path: polygon(4% 0, 80% 0, 60% 90%, 0 55%);
                }
            }

            @include desktop-medium {
                & img {
                    clip-path: polygon(4% 0, 70% 0, 60% 100%, 0 60%);
                }
            }
        }

        #{$this}--no-bg & {
            @include desktop {
                height: 100%;
            }
        }
    }

    &__figure {
        position: absolute;
        top: 390px;
        left: -35px;
        z-index: 2;
        width: 80%;
        height: 250px;

        @include tablet {
            top: calc(40% - 125px);
            left: 50%;
            height: 400px;
        }

        @include desktop {
            top: 0;
            left: col(7);
            z-index: 1;
            display: block;
            width: col(5);
            height: 90%;
            pointer-events: none;

        }

        @media screen and (max-width: 390px) {
            top: 320px;
            left: -20px;
        }

        &--1 {
            @include desktop {
                & svg {
                    transform: rotate(-35deg);
                }
            }
        }

        &--2 {
            @include desktop {
                top: 0;
                height: 100%;
            }
        }

        &--3 {
            @include desktop {
                top: 0;
                left: col(6);
                height: 80%;
            }
        }

        &--4 {
            top: 330px;

            @include tablet {
                top: calc(40% - 125px);
            }

            @include desktop {
                top: -50%;
                left: col(6.5);
                height: 120%;
            }

            @media screen and (max-width: 390px) {
                top: 300px;
            }
        }

        &--5 {
            top: 330px;

            @include tablet {
                top: calc(40% - 125px);
            }

            @include desktop {
                top: 0;
                left: col(7);
                height: 100%;

                & svg {
                    transform: rotate(72deg);
                }
            }

            @media screen and (max-width: 390px) {
                top: 300px;
            }
        }

        & svg {
            width: 100%;
            height: 100%;
            fill: var(--theme-color-accent) !important;

        }

        #{$this}--home & {
            top: 428px;

            @include tablet {
                top: calc(40% - 125px);
                left: 50%;
                height: 400px;
            }

            @include desktop {
                top: 0;
                left: col(7);
                height: 90%;

            }

            @media screen and (max-width: 390px) {
                top: 330px;
            }
        }

        #{$this}--no-bg & {
            @include desktop {
                top: -20%;
            }
        }
    }

    .in-cms & {
        max-height: 1440px;
    }

    &--medium-height {
        @include desktop {
            min-height: 65vh;
        }
    }

    &--no-bg {
        background: $color-extra-light-grey;
        color: $color-black;

        @include desktop {
            min-height: 0;
        }
    }
}
