.section-search {
    $this: &;

    cursor: pointer;

    @include hover {
        button {
            background-color: var(--theme-color-secondary);
            color: var(--theme-color-primary);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: col(1) $default-mobile-side-padding;
        font-family: $ff-suisse;
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        text-align: center;

        @include desktop-medium {
            width: col(5);
            margin: 0 auto;
            font-size: 61px;
            line-height: 72px;
        }

        & i {
            font-family: $ff-tiempos;
            font-style: italic;
            font-weight: $fw-light;
        }
    }

    & button {
        margin-top: 40px;
        transition: color 0.3s linear, background-color 0.3s linear;

        @include desktop {
            margin-top: 60px;
        }

    }
}
