.article-cards-grid {
    grid-template-columns: 1fr;
    display: grid;
    gap: 45px;

    @include tablet {
        grid-row-gap: 33px;
        grid-template-columns: 1fr 1fr;
    }

    @include desktop {
        grid-template-columns: 1fr 1fr 1fr;
    }

    &--article-layout {
        gap: 32px;

        @include tablet {
            grid-row-gap: 44px;
            grid-template-columns: 1fr 1fr;
            padding-right: 0;
            padding-left: 0;
        }

        @include desktop {
            grid-row-gap: 32px;
            grid-template-columns: 1fr 1fr;
        }
    }
}
