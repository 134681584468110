.footer-subnav {
    opacity: 0;
    visibility: hidden;
    height: 0;
    --submenu-width: 180px;


    @include desktop {

        position: absolute;
        top: 0;
        left: 100%;
        padding-left: 0;
    }
    @include desktop-medium {
        padding-left: 20px;
    }
    @media screen and (min-width: 1750px) {
        --submenu-width: 320px;
    }



    &__list {
        display: flex;
        flex-direction: column;
        min-width: var(--submenu-width);
    }

    &__item {
        & + & {
            margin-top: 14px;
        }
    }



    &__link {
        @include link-reset();

        color: var(--color-white);
        font-family: $ff-suisse;
        font-weight: 300;
        font-size: 16px;
        line-height: 16px;
    }

    &.is-visible {
        opacity: 1;
        visibility: visible;
        height: auto;
        transition: opacity $transition-default;

    }
}
