.add-bookmark-btn {
    $this: &;

    &__fake-icon {
        transition: fill 0.3s linear;
        fill: transparent;

        #{$this}.is-active & {
            fill: currentColor;
        }
    }
}
