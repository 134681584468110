.form {
    $this: &;

    @include form;

    &__item {
        &--full-width {
            grid-column: 1 / calc(var(--form-grid-columns) + 1);
        }
    }

    &__submit,
    &__reset {
        margin-top: 10px;
        text-align: center;

        #{$this}--2cols & {
            grid-column: 1 / calc(var(--form-grid-columns) + 1);
        }
    }

    &__policy {
        max-width: calc(100vw - var(--container-gap) * 2);
        font-size: 12px;
        line-height: 1.67;

        @include tablet {
            max-width: 540px;
        }

        a {
            color: inherit;
            transition: opacity 0.2s;
        }

        a:hover {
            text-decoration: none;
        }

        a:active {
            opacity: 0.6;
        }
    }

    &__group-title {
        font-weight: $fw-bold;
    }

    &__group {
        display: grid;
        margin-top: 20px;
        margin-bottom: 20px;
        gap: var(--form-gap);

        & + & {
            margin-top: 0;
        }
    }

    &__btn-group {
        @include mobile-small {
            display: flex;
            justify-content: center;
        }

        > * {
            margin-top: 0;
            margin-bottom: 0;

            @include mobile-small {
                margin-right: 10px;
                margin-left: 10px;
            }
        }

        > * + * {
            @include mobile-small-only {
                margin-top: 10px;
            }
        }
    }

    &--2cols {
        @include form-2cols;
    }
}
