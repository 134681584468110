.iframe-component {
    $this: &;

    position: relative;
    padding-top: 56.25%;

    &--custom-sized {
        padding-top: 0;
    }

    iframe,
    &__iframe {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border: none;

        #{$this}--custom-sized & {
            position: static;
        }
    }
}
