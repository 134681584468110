.FormCaptcha {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .Form__Element__Caption {
        order: -4;
        width: 100%;

        @include tablet {
            order: -6;
        }
    }

    .FormTextbox__Input {
        margin-top: 10px;

        @include tablet {
            flex-grow: 1;
            order: -4;
            width: calc(45% - 20px) !important;
            margin-right: 20px;
        }
    }

    .FormCaptcha__Image {
        order: -2;
        max-width: calc(100% - 50px - 10px);
        margin-top: 10px;
        margin-right: 10px;

        @include tablet {
            max-width: 100%;
            height: 64px;
            margin-right: 0;
        }
    }

    .FormCaptcha__Refresh {
        --form-captcha-btn-size: 50px;

        position: relative;
        overflow: hidden;
        display: block;
        width: var(--form-captcha-btn-size) !important;
        height: var(--form-captcha-btn-size) !important;
        margin-top: 10px;
        padding: 0;
        background: none;
        border: 1px solid transparent;
        border-radius: 0;
        color: inherit;
        font-size: 0 !important;
        cursor: pointer;
        outline: none;
        transition: opacity 0.2s, border 0.2s, background-color 0.2s, color 0.2s, box-shadow 0.2s;

        @include tablet {
            --form-captcha-btn-size: 64px;

            order: 2;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            display: block;
        }

        &::before {
            top: 50%;
            left: 50%;
            transform: rotate(45deg) translate(-50%, -50%);
            transform-origin: top left;
            width: 15px;
            height: 15px;
            border-width: 1.25px;
            border-color: transparent currentColor currentColor currentColor;
            border-radius: 50%;
            border-style: solid;
        }

        &::after {
            position: absolute;
            top: calc(var(--form-captcha-btn-size) / 2 - 11px);
            left: 50%;
            width: 0;
            height: 0;
            border-width: 3.125px 0 3.125px 5px;
            border-color: transparent transparent transparent currentColor;
            border-style: solid;
        }

        &:active {
            opacity: 0.7;
        }

        @include hover-focus {
            background-color: $color-white;
            border-color: $color-main-dark;
            box-shadow: var(--theme-input-focus-box-shadow, 0 0 0 2px #FFFFFF);
            color: $color-main-dark;

            &::before {
                opacity: 1;
            }
        }
    }
}
