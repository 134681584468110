.select-list {
    width: 100%;
    max-height: 385px;
    padding-bottom: 7.5px;
    background-color: var(--color-white);

    &__btn {
        @include reset-button;

        width: 100%;
        padding: 7.5px 27px;
        background-color: var(--color-white);
        color: var(--color-main-dark);
        font-size: 18px;
        text-align: left;
        cursor: pointer;
        transition: background-color 0.3s linear;

        @include desktop {
            font-size: 20px;
        }

        @include hover-focus {
            background-color: var(--color-meta-grey);
        }
    }
}
