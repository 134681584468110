.hero-title {
    $mobile-font-space: 20px;
    $font-space: 40px;
    $hero: '.hero';
    $symbolRatioWidthToHeight: 35 / 19;

    --inview: calc(100vw - 40px);
    --letters-count: 14;

    z-index: 2;
    margin-top: 200px;
    margin-bottom: col(0.5);
    color: var(--theme-color-secondary);
    font-family: $ff-suisse;
    font-size: calc(var(--inview) / var(--letters-count) * #{$symbolRatioWidthToHeight});
    font-weight: $fw-medium;
    line-height: 1;
    text-align: center;
    word-break: break-word;

    @include desktop {
        --inview: calc(100vw - #{col(2)});

        text-align: left;
    }

    @include desktop-wide {
        --inview: calc(#{$container-max-width} - #{col(2)});
    }

    &--medium {
        font-size: 64px;

        @media screen and (max-width: 390px) {
            font-size: 54px;
        }

        @include mobile-wide {
            font-size: 80px;
        }

        @media screen and (min-width: 1240px) {
            font-size: 119px;
        }
    }

    &--color-accent {
        & i {
            color: var(--theme-color-accent) !important;
        }
    }

    & i {
        font-family: $ff-tiempos;
        font-style: italic;
        font-weight: $fw-light;
    }

    & span {
        margin-right: $mobile-font-space;

        @include desktop {
            margin-right: $font-space;
        }
    }

    #{$hero}--no-bg & {
        margin-bottom: 0;
        color: $color-black;
    }

}
