/*
    general layout styles
    put .container here or other global layout classes

    example:

    .container {
        max-width: $containerMaxWidth;
        margin-left: auto;
        margin-right: auto;
    }

*/

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    position: relative;
    overflow-x: hidden;
    font-size: 100%;

    &.is-blocked {
        overflow: hidden;
    }

    &.is-blocked-touch {
        position: fixed;
        overflow-y: scroll;
        width: 100%;
        height: auto;
    }
}

body {
    margin: 0;
    background-color: var(--color-extra-light-grey);
    color: var(--color-main-dark);
    font-family: $ff-suisse;
    font-weight: $fw-light;
    -webkit-font-smoothing: antialiased;
}

figure {
    margin: 0;
}

blockquote {
    $icon-ratio: 53.6 / 43.3;
    $quote-horizontal-gap: 55px;
    $quote-horizontal-gap-mobile: 45px;

    --quote-icon-width: 30px;

    position: relative;
    margin: 0;
    padding: 8px $quote-horizontal-gap-mobile;
    font-family: $ff-tiempos;
    font-size: 37px;
    font-style: italic;
    line-height: 1;

    @include tablet {
        padding-right: $quote-horizontal-gap;
        padding-left: $quote-horizontal-gap;
        font-size: 48px;
    }

    @include desktop-wide {
        font-size: 61px;
    }

    @include tablet {
        --quote-icon-width: 34px;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: var(--quote-icon-width);
        height: calc(var(--quote-icon-width) * #{$icon-ratio});
        background-color: var(--theme-color-primary);
        mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0My4zMjMiIGhlaWdodD0iNTMuNjE2Ij4NCiAgPHBhdGggZD0ibTM5LjEyOSAzLjUtOC4yMzYgNDUuMjVMMy41IDM3LjYwNlYzLjVaIiBzdHJva2Utd2lkdGg9IjdweCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwIi8+DQo8L3N2Zz4=');
        mask-size: cover;
    }

    &::before {
        top: 0;
        left: 0;
    }

    &::after {
        right: 0;
        bottom: 0;
    }

    & + figcaption {
        display: block;
        margin-top: 10px;
        padding-right: $quote-horizontal-gap-mobile;
        padding-left: $quote-horizontal-gap-mobile;
        font-family: $ff-suisse;
        font-size: 20px;
        font-style: normal;
        line-height: 1.5;
        text-align: right;

        @include tablet {
            padding-right: $quote-horizontal-gap;
            padding-left: $quote-horizontal-gap;
        }
    }
}

.visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    white-space: nowrap;
}

.devider {
    margin-bottom: 30px;
}

.main-wrapper {
    position: relative;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;

    .in-cms & {
        min-height: auto;
    }
}

.container {
    @include container;

    &--hero {
        height: 100%;
    }

    &--text-media {
        position: relative;
        width: 100%;
    }

    &--texts-slider {
        width: 100%;

        @include desktop {
            padding-right: col(1);
            padding-left: col(1);
        }

        @include desktop-medium {
            padding-right: col(2);
            padding-left: col(2);
        }
    }

    &--gaped {
        @include desktop {
            --container-gap: #{col(1)};
        }
    }
}

.inner-page {
    display: flex;
    flex-grow: 1;
    width: 100%;
}

.page-content {
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

.components {
    $this: &;

    &__item {
        #{$this} > & ~ & {
            margin-top: $default-mobile-vertical-gap-between-components;

            @include tablet {
                margin-top: col(1);
            }

            &:last-child {
                margin-bottom: $default-mobile-vertical-gap-between-components;

                @include tablet {
                    margin-bottom: col(1);
                }

                .components & {
                    margin-bottom: 0;
                }
            }

            &--big:last-child,
            &--half-big:last-child {
                margin-bottom: 0;
            }
        }

        #{$this} > &--anchor + &,
        #{$this} > &--half-big + &--big,
        #{$this} > &--big + &--big {
            margin-top: 0;
        }
    }
}

.content-components {
    $this: &;

    &__item {
        #{$this} > & ~ & {
            margin-top: get-offset(4);
        }

        #{$this} > &--anchor + &,
        #{$this} > &--half-big + &--big,
        #{$this} > &--big + &--big {
            margin-top: 0;
        }
    }
}

.hidden-content {
    display: none;
}

[data-whatintent='touch'] *:focus {
    outline: none;
}
