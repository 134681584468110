
.texts-slider {
    $arrowPadding: col(1);

    position: relative;
    overflow: hidden;
    max-width: 100%;

    &__slider-container {
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;

    }
    &__arrows {
        & > button[disabled] {
            opacity: 0.4;
        }
    }


    &__arrow-next {
        margin-left: 15px;
    }

    &__list {
        display: flex;
    }

    &__card {
        min-width: 100%;
        max-width: 100%;
    }
}
