.text-media {
    $this: &;
    $text-media-slider: '.text-media-slider';

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @include desktop {
        flex-direction: row;
    }

    &__col {
        position: relative;
        width: 100%;

        @include desktop {
            width: 50%;
            height: auto;
        }

        &--text-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 24px 0 0;

            @include desktop {
                padding: 0 col(1);
            }

            #{$text-media-slider} & {
                justify-content: flex-start;
                padding-top: 80px;
                padding-bottom: 55px;

                @include desktop {
                    padding-top: 130px;
                }
            }

            #{$this}--only-texts & {
               padding: 0;

                @include desktop {
                    padding: 0 col(1);
                }
            }
        }

        &--media-content {
            height: var(--media-height);

            @include desktop {
                height: auto;
                min-height: 463px;
            }

            #{$this}--media-right & {
                order: 0;

                @include desktop {
                    order: 1;
                }
            }
        }

        #{$this}--full & {
            @include desktop {
                width: 100%;
            }
        }
    }

    #{$text-media-slider} & {
        @include desktop-medium {
            padding-right: col(1);
            padding-left: col(1);
        }
    }
}
