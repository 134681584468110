.parent-logo {
    $this: &;

    @include link-reset;

    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: var(--color-main-dark);
    color: var(--color-white);
    transition: background-color 0.3s linear, color 0.3s linear;

    @include tablet {
        width: max-content;
    }

    @include desktop-medium {
        padding: 20px 28px;
    }

    &__icon {
        display: flex;
        align-items: center;
        width: 35px;
        height: 35px;
        transition: color 0.3s linear;
        fill: currentColor;

        @include desktop-medium {
            width: 109px;
            height: 41px;
        }

        .icon {
            width: 35px;
            height: 35px;

            @include mobile-tiny-only {
                width: 22px;
                height: 22px;
            }

            @include desktop-medium {
                width: initial;
                height: initial;
            }
        }

        & .text {
            display: none;

            @include desktop-medium {
                display: block;
            }
        }
    }

    &__text {
        margin-left: 10px;
        font-family: $ff-tiempos;
        font-size: 14px;
        font-weight: $fw-bold;
        letter-spacing: -0.04px;
        line-height: 11px;
        white-space: nowrap;

        @include mobile-tiny-only {
            transform: scale(0.9);
            transform-origin: left;
        }

        @include desktop-medium {
            margin-left: 20px;
            padding-left: 20px;
            border-left: 1px solid currentColor;
            font-size: 20px;
            letter-spacing: -0.06px;
            line-height: 20px;
        }
    }

    &__text-sub {
        display: block;
        font-size: 9px;
        letter-spacing: 0.23px;
        text-align: center;

        @include desktop-medium {
            font-size: 12px;
            letter-spacing: 0.3px;
        }
    }

    @include hover-focus {
        background-color: var(--color-white);
        color: var(--color-black);

        .icon {
            color: var(--color-brand-red);
        }
    }
}
