.site-logo {
    @include objectFitImageParent;

    position: relative;
    width: var(--page-header-logo-size);
    height: var(--page-header-logo-size);

    .page-header & {
        transition: filter 0.3s linear;

        @include hover-focus {
            filter: invert(1);
        }
    }

    &--footer {
        display: block;
        width: 150px;
        height: 55px;

        @include desktop {
            width: 200px;
            height: 74px;
        }

        & .icon {
            width: 100%;
            height: 100%;
        }
    }
}
