.page-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $layer-navbar;
    /* autoprefixer: ignore next warning */
    grid-template-areas: 'sitelogo nav nav btns' 'ml mm mm mr' 'parentlogo parentlogo download download';
    grid-template-columns: var(--page-header-logo-size) calc(50vw - var(--page-header-logo-size)) calc(
        50vw - 150px
    ) 150px;
    /* autoprefixer: ignore next warning */
    grid-template-rows: auto 1fr auto;
    display: grid;
    width: 100%;
    height: 100%;
    pointer-events: none;

    @include tablet {
        /* autoprefixer: ignore next warning */
        grid-template-areas: 'sitelogo nav mt mt btns' 'ml mm mm mm mr' 'parentlogo parentlogo gap gap download';
        grid-template-columns: var(--page-header-logo-size) auto 1fr auto;
    }

    @include desktop {
        grid-template-columns: col(1) auto 1fr auto;
    }

    &__nav.nav {
        grid-area: nav;
        pointer-events: none;

        * {
            pointer-events: initial;
        }
    }

    &__site-logo {
        grid-area: sitelogo;
    }

    &__parent-logo {
        grid-area: parentlogo;

        @include desktop {
            display: flex;
            align-items: flex-end;
        }
    }

    &__download {
        grid-area: download;
    }

    &__btns {
        grid-area: btns;
    }

    &__parent-logo,
    &__download {
        //yes, this transition uses delay
        transition: transform 0.3s linear 0.3s;

        .is-scrolled-down & {
            @include until-desktop {
                transform: translateY(51px);
            }
        }

        .is-end-of-page & {
            @include until-desktop {
                transform: none;
            }
        }
    }

    & > * {
        pointer-events: initial;
    }
}
