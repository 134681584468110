.i-slider-navs {
    $animDurationAndAutoplay: var(--autoplaytime, var(----autoplay));

    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 10px;

    @include desktop {
        height: 70px;
        padding: 0 14px;
    }


    &__bullet {
        position: relative;
        flex-grow: 1;
        height: 100%;
        margin: 0 10px;
        border: none;
        cursor: pointer;

        @include desktop {
            margin: 0 14px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 0;
            height: 2px;
            background: var(--color-white);
            border-radius: 2px;

        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            background: rgba(255,255,255,0.4);

        }

        &.glide__bullet--active {
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 2px;
                background: rgba(255,255,255,0.4);

            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                max-width: 100%;
                height: 4px;
                background: var(--color-white);
                border-radius: 2px;
                animation: $animDurationAndAutoplay linear bullet-width ;

            }

            &.stop-animated {
                &::after {
                    width: 0%;
                    transition-property: none;
                }
            }
        }

        &.stop-animated {
            &::after {
                width: 0%;
                animation: 0.4s bullet-width-stop;
            }
        }

        &.is-watched {
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                max-width: 100%;
                height: 4px;
                background: white;
                transition: width 0s linear;

            }

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                max-width: 100%;
                height: 4px;
                background: white;
                transition: width 0s linear;

            }
        }
    }

}


@keyframes bullet-width {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes bullet-width-stop {
    to {
        width: 0%;
    }
}
