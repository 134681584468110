.text-media-slider {
    $arrowPadding: col(1);

    position: relative;
    overflow: hidden;
    max-width: 100%;

    &__slider-container {
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    &__arrows {
        position: absolute;
        top: calc(var(--media-height) + 24px);
        left: var(--container-gap);
        z-index: 2;

        @include desktop {
            top: 65px;
            left: calc(50% + #{$arrowPadding});

            &--media-right {
                left: col(1);
            }
        }

        @include desktop-medium {
            &--media-right {
                left: col(2);
            }
        }
        & > button[disabled] {
            opacity: 0.4;
        }
    }

    &__arrow-next {
        margin-left: 15px;
    }

    &__list {
        @include slider-fade;
    }

    &__card {
        min-width: 100%;
        max-width: 100%;
    }
}
