.video {
    $this: &;

    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        display: block;
        padding-bottom: 56.25%;
    }

    &::after {
        content: '';
        position: absolute;
        opacity: 0.2;
        z-index: 4;
        display: block;
        width: 100%;
        height: 100%;
        background: #000000;
        pointer-events: none;
        transition: opacity 0.4s linear;

        @include not-touch {
            opacity: 0;
        }
    }

    &__media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__fullbleed-btn {
        @include reset-button;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-family: $ff-suisse;
        cursor: pointer;

        @include hover-focus {
            & + #{$this}__fake-btn {
                background-color: $color-white;
                color: $color-main-dark;
            }
        }
    }

    &__element,
    &__element + iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &:not(iframe) {
            object-fit: cover;
        }
    }

    &__fake-btn {
        position: absolute;
        opacity: 1;
        visibility: visible;
        z-index: 5;
        pointer-events: none;
        transition: background-color 0.3s linear, color 0.3s linear;
    }

    &__btn.is-play {
        opacity: 0;
    }

    @include hover-focus {
        &::after {
            opacity: 0.2;
        }
    }

    &.is-play {
        cursor: default;
    }
}
