.author {
    display: flex;
    align-items: center;
    line-height: 1.4;

    &__img {
        width: 83px;
        height: 83px;
        margin-right: 16px;
        object-fit: cover;
    }

    &__name {
        font-weight: $fw-bold;
    }

    &__subtitle {
        font-size: 13px;
    }
}
