.team-slider {
    $this: &;

    overflow: hidden;
    max-width: 100%;

    &__head {
        position: relative;
        margin-bottom: 34px;
    }

    &__inner {
        @include desktop {
            padding-right: col(1);
            padding-left: col(1);
        }

        @include desktop-medium {
            padding-right: col(2);
            padding-left: col(2);
        }
    }

    &__title {
        font-family: $ff-tiempos;
        font-size: 24px;
        font-style: italic;
        font-weight: $fw-light;
        line-height: (58/42);

        @include until-desktop {
            text-align: center;
        }

        @include desktop {
            font-size: 42px;
        }
    }

    &__controls {
        grid-template-columns: auto auto;
        display: grid;
        width: max-content;
        margin-top: 22px;
        margin-right: auto;
        margin-left: auto;
        gap: 16px;

        @include desktop {
            display: none;
        }

        #{$this}.is-not-enough & {
            display: none;
        }
    }

    &__slider-container {
        max-width: col(2);
        margin-right: auto;
        margin-left: auto;

        @include tablet {
            max-width: 100%;
        }
    }

    &__list {
        display: flex;
    }

    &__card {
        min-width: col(2);
        max-width: col(2);
        padding-right: 30px;

        @include desktop {
            box-sizing: content-box;
            min-width: col(1.75);
            max-width: col(1.75);
            padding-right: col(0.25);
        }
    }
}
