.Form__Description {
    margin: 20px 0;
    font-size: 18px;
    text-align: center;
}

.Form__Element__Caption {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 5px;
}

.kf-form-section-header,
.Form__Title {
    grid-column: 1 / calc(var(--form-grid-columns) + 1);
    margin-bottom: 30px;
    font-size: 30px;
    text-align: center;

    @include tablet {
        font-size: 42px;
    }
}

.FormStep {
    display: grid;
    gap: 16px;
}

.kf-form-section {
    --container-width: 776px;

    @include form;

    width: 100%;
}

.kf-form-section-cols-2 {
    @include form-2cols;
}

.kf-form-section-cols-3 {
    @include form-3cols;
}

.kf-form-section-cols-4 {
    @include form-4cols;
}

.Form__Element__ValidationError {
    margin-top: 5px;
    color: #EA000E;
    font-size: 14px;
}

.FormParagraphText {
    @include richtext;
}

.Form__Status__Message {
    border: 2px solid;
}

.EPiServerForms .hide {
    display: none !important;
}
