.form-controls {
    @import './reset-styles';
    @import './common';
    @import './navigation';
    @import './text-field';
    @import './buttons';
    @import './captcha';
    @import './checkbox';
    @import './file-upload';
    @import './form-range';
    @import './textarea';
    @import './select';
}
