.footer-nav {
    $this: &;

    --submenu-width: 180px;

    width: 100%;

    @media screen and (min-width: 1750px) {
        --submenu-width: 320px;
    }

    &__list {
        position: relative;
        display: flex;
        flex-direction: column;

        @include desktop {
            width: calc(100% - var(--submenu-width));
        }
    }

    &__item {
        width: 100%;
        margin-bottom: 20px;

        @include desktop {
            width: 100%;
            margin-bottom: 0;
        }

        & + & {
            @include desktop {
                margin-top: 45px;
            }
        }

        &.is-opened {
            & > .footer-subnav {
                opacity: 1;
                visibility: visible;
                height: auto;
                transition: $transition-default;
            }
        }
    }

    &__title {
        @include reset-button();

        position: relative;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
        padding-right: 0;
        padding-left: 0;
        color: $color-white;
        font-family: $ff-tiempos;
        font-size: 22px;
        font-style: italic;
        font-weight: 300;
        line-height: 22px;
        cursor: pointer;

        @include tablet {
            justify-content: flex-start;
            width: auto;
        }

        @include desktop {
            font-size: 18px;
            width: 100%;
            font-family: $ff-suisse;
            font-style: normal;
            font-weight: $fw-regular;
            letter-spacing: 1px;
            white-space: nowrap;

            &::after {
                content: '';
                position: absolute;
                top: 7px;
                right: 0;
                width: 0%;
                height: 1px;
            }
        }

        @include desktop-medium {
            font-size: 22px;
        }

        @include active-hover-focus {
            @include desktop {
                font-family: $ff-tiempos;
                font-style: italic;
                font-weight: 300;
                outline: none;
            }
        }

        &.is-active {
            @include desktop {
                font-family: $ff-tiempos;
                font-style: italic;
                font-weight: 300;

                &::after {
                    content: '';
                    position: absolute;
                    top: 7px;
                    left: 0;
                    transform-origin: left;
                    width: calc(100% - 15px);
                    max-width: 100%;
                    height: 1px;
                    background: var(--color-light-grey2);
                    transition: width $transition-default;
                }
            }
        }

        & span {
            position: relative;
            z-index: 2;
            padding-right: 40px;
            background: var(--color-main-dark);
            text-align: left;

            @include desktop {
                padding-right: 20px;
            }

            @include desktop-medium {
                padding-right: 40px;
            }
        }
    }

    &__icon {
        transition: transform $transition-default;
        fill: var(--color-white);

        @include tablet {
            margin-left: 10px;
        }

        @include desktop {
            display: none;
        }

        #{$this}__title.is-active & {
            transform: rotate(180deg);
            transition: transform $transition-default;
        }
    }
}
