input[type='checkbox'] {
    @include checkbox;
}

input[type='radio'] {
    @include checkbox;

    border-radius: 50%;
}

.FormChoice {
    $this: &;

    .FormChoice--Image__Item__Caption {
        overflow: hidden;
        margin-left: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    legend {
        margin-bottom: 20px;
    }

    fieldset {
        padding: 0;
        border: 0;
    }

    label {
        position: relative;
        display: block;
        align-items: center;
        min-height: 36px;
        padding-left: 60px;
        line-height: 1.4;
        cursor: pointer;
    }

    label + label {
        margin-top: 20px !important;
    }

    input[type='radio'],
    input[type='checkbox'] {
        position: absolute;
        top: 0;
        left: 0;
    }

    &--Image {
        label {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            padding-left: 0;
        }

        img {
            width: 100px;
            height: 100px;
            object-fit: cover;

            @include tablet {
                width: 200px;
                height: 200px;
            }
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
        }

        input:checked ~ img {
            border: 0 !important;
        }
    }

    &.Form__Element {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
