.text-media-article {
    $rich-text: '.richtext';

    &__pretitle {
        margin-bottom: 16px;
        font-weight: $fw-bold;
        line-height: 25px;
    }

    &__title,
    #{$rich-text} &__title {
        position: relative;
        margin-top: 12px;
        margin-bottom: 32px;
        padding-left: 40px;
        font-family: $ff-tiempos;
        font-size: 39px;
        font-style: italic;
        font-weight: $fw-light;
        line-height: 39px;

        @include desktop {
            margin-top: 16px;
            padding-left: 45px;
        }

        a & {
            color: $color-main-dark;
        }

        & .icon {
            position: absolute;
            top: 2px;
            left: -3px;
            margin-right: 20px;
            fill: var(--theme-color-primary);
        }
    }

    &__title,
    &__annotation,
    & .truncate {
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
    }

    &__title {
        -webkit-line-clamp: 2;
        line-clamp: 2;
    }

    &__annotation {
        -webkit-line-clamp: 4;
        line-clamp: 4;
    }

    & > .btn {
        margin-top: 30px;
    }

    & .truncate {
        -webkit-line-clamp: 7;
        line-clamp: 7;

        & *:first-child {
            margin-top: 0;
        }
    }

    &__annotation,
    #{$rich-text} &__annotation {
        margin-bottom: 30px;
        font-weight: $fw-bold;

        @include desktop {
            font-size: 20px;
            line-height: 31px;
        }
    }
}
