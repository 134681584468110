.article-aside {
    --article-aside-item-gap: 24px;

    @include desktop {
        --article-aside-item-gap: 36px;
    }

    &__main {
        @include until-desktop {
            @include container;

            position: relative;
            width: 100%;
            padding-top: calc(60px + var(--page-header-logo-size));
            background-color: var(--theme-color-primary);
        }

        @supports (display: contents) {
            padding-bottom: var(--aside-main-offset-bottom);
        }

        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }

    &__main > * {
        @include until-desktop {
            position: relative;
            z-index: 1;
        }
    }

    &__title {
        margin-top: 32px;
        margin-bottom: 32px;

        @include desktop {
            margin-top: var(--article-aside-item-gap);
            margin-bottom: var(--article-aside-item-gap);
        }
    }

    &__description {
        font-size: 16px;
        line-height: 1.6;

        @include desktop {
            font-size: 20px;
        }
    }

    &__item + &__item {
        margin-top: var(--article-aside-item-gap);
    }

    &__secondary {
        @include desktop {
            margin-top: var(--article-aside-item-gap);
        }

        @include mobile-only {
            @supports (display: contents) {
                position: relative;
                margin-bottom: -16px;
            }
        }

        @include mobile-only {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @include tablet-only {
            background-color: var(--theme-color-primary);
        }

        @include until-desktop {
            @include container;

            z-index: 2;
            order: 1;
            width: 100%;
            padding-top: 45px;
            padding-bottom: 45px;
        }

        &::before {
            @include mobile-only {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                clip-path: polygon(100% 0, 100% 100%, 0 95%, 0 5%);
                display: block;
                width: 100%;
                height: 100%;
                background-color: var(--theme-color-primary);
            }
        }

        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }

    &__btns {
        margin-top: var(--article-aside-item-gap);

        @include desktop {
            position: absolute;
            right: 15px;
            bottom: 15px;
            margin-top: 0;
        }
    }
}
