.input {
    $recommended: '.recommended-reading-page';

    @include input;
    #{$recommended} & {
        --input-border-color: #{$color-meta-grey};
    }

    &--form {
        @include input-form;
    }

    &--mobile-small {
        @include until-desktop {
            height: 40px;
            padding: 9px 15px 10px;
        }
    }

    &.is-nav {
        height: 50px;
        background-color: var(--theme-color-accent);
        color: var(--theme-color-secondary);
        font-size: 18px;
        transition: background-color 0.3s linear, color 0.3s linear;

        @include desktop {
            height: 80px;
            padding: 25px;
            font-size: 24px;
        }

        &.is-active {
            background-color: var(--color-white);
            color: var(--color-main-dark);
            font-style: italic;
        }
    }
}

textarea.input {
    @include textarea;
}
