.filters-pagination {
    grid-template-columns: auto auto;
    display: grid;
    justify-content: center;
    gap: 18px;

    @include tablet {
        grid-template-columns: auto auto auto;
    }

    @include desktop {
        gap: 15px;
    }

    &__btn-back {
        transform: rotate(180deg);
    }

    &__pages {
        @include reset-list;

        display: none;

        @include tablet {
            display: flex;
        }
    }

    &__page {
        @include reset-button;

        display: block;
        padding: 7px 4px;
        font-family: $ff-suisse;
        font-size: 16px;
        font-weight: $fw-light;
        line-height: (24/16);
        cursor: pointer;
        transition: transform 0.2s ease-out;

        @include hover-focus {
            transform: scale(1.05);
        }

        &.is-active {
            font-weight: $fw-medium;
            cursor: inherit;
            pointer-events: none;
        }
    }
}
