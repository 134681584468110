.recomended-reading {
    $this: &;

    @include container;

    position: relative;
    order: 1;
    margin-right: 0;
    margin-left: 0;
    padding-top: 60px;
    padding-bottom: 60px;
    background: $color-white;

    @include desktop {
        --container-width: var(--content-width);
        --grid-columns: 7;

        padding: 75px col(1);
    }

    &::before {
        @include desktop {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: calc((100vw - var(--aside-width) - 100%) / 2 + 100%);
            height: 100%;
            background: $color-white;
        }
    }

    &__title {
        margin-bottom: 24px;
        color: $color-main-dark;
        font-family: $ff-tiempos;
        font-size: 24px;
        font-style: italic;
        font-weight: $fw-light;
        line-height: 1.4;
        text-align: left;
        word-break: break-word;

        @include desktop {
            margin-bottom: 47px;
            font-size: 38px;
        }
    }

    & > * {
        position: relative;
    }
}
