.socials {
    display: flex;

    @include desktop {
        justify-content: flex-end;
    }

    &__list {
        display: flex;

        @include desktop {
            flex-direction: column;
        }


    }


    &__link {
        @include link-reset;
    }

    &__item {
        & + & {
            & .social-item {
                @include until-desktop {
                    border-left: none;
                }

                @include desktop {
                    border-top: none;
                }
            }
        }
    }
}

.social-item {
    width: 60px;
    height: 60px;
    border: 1px solid var(--color-light-grey2);
}
